<template>
  <div class="admin-dashboard">
    <!-- Left Panel -->
    <div class="left-panel">
      <div class="logo-container">
        <img src="/img/cf_circle.png" alt="Carolina's Finest Camp Logo" class="cf-logo">
        <div class="logo-text">
          <h3>Admin Portal</h3>
          <p>Camp Management</p>
        </div>
      </div>
      
      <nav class="panel-nav">
        <button 
          @click="activeTab = 'pending'" 
          :class="['nav-btn', { active: activeTab === 'pending' }]"
        >
          Pending Applications
        </button>
        <button 
          @click="activeTab = 'approved'" 
          :class="['nav-btn', { active: activeTab === 'approved' }]"
        >
          Approved Applications
        </button>
        <button 
          @click="activeTab = 'sessions'" 
          :class="['nav-btn', { active: activeTab === 'sessions' }]"
        >
          Session Management
        </button>
      </nav>
      
      <div class="panel-footer">
        <router-link to="/admin/logout" class="logout-btn">
          Logout
        </router-link>
      </div>
    </div>

    <!-- Main Content Area -->
    <div class="main-content">
      <div v-if="loading" class="loading">
        <div class="loading-spinner"></div>
        <p>Loading data...</p>
      </div>

      <div v-else-if="error" class="error-message">
        {{ error }}
      </div>

      <div v-else>
        <!-- Pending Applications Tab -->
        <div v-if="activeTab === 'pending'" class="tab-content">
          <div class="tab-header">
            <h2>Pending Applications</h2>
            <div class="actions">
              <button @click="refreshData" class="btn secondary">
                Refresh
              </button>
            </div>
          </div>

          <div class="applications-grid">
            <div v-for="app in pendingApplications" :key="app.confirmation_number" class="application-card">
              <div class="card-header">
                <h3>{{ app.name }}</h3>
                <span class="status-badge pending">Pending</span>
              </div>
              <div class="card-content">
                <div class="info-row">
                  <span class="label">Email:</span>
                  <span>{{ app.email }}</span>
                </div>
                <div class="info-row">
                  <span class="label">Phone:</span>
                  <span>{{ app.cellPhone }}</span>
                </div>
                <div class="info-row">
                  <span class="label">Session:</span>
                  <span>{{ app.session }}</span>
                </div>
                <div class="info-row">
                  <span class="label">Experience:</span>
                  <span>{{ app.yearsOfExperience }} years</span>
                </div>
              </div>
              <div class="card-actions">
                <button @click="approveApplication(app)" class="btn success">
                  Approve
                </button>
                <button @click="rejectApplication(app)" class="btn danger">
                  Reject
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Approved Applications Tab -->
        <div v-if="activeTab === 'approved'" class="tab-content">
          <div class="tab-header">
            <h2>Approved Applications</h2>
            <div class="actions">
              <button @click="refreshData" class="btn secondary">
                Refresh
              </button>
            </div>
          </div>

          <div class="applications-grid">
            <div v-for="app in approvedApplications" :key="app.confirmation_number" class="application-card">
              <div class="card-header">
                <h3>{{ app.name }}</h3>
                <span class="status-badge approved">Approved</span>
              </div>
              <div class="card-content">
                <div class="info-row">
                  <span class="label">Email:</span>
                  <span>{{ app.email }}</span>
                </div>
                <div class="info-row">
                  <span class="label">Session:</span>
                  <span>{{ app.session }}</span>
                </div>
                <div class="info-row">
                  <span class="label">Camp Date:</span>
                  <span>{{ app.campDate }}</span>
                </div>
              </div>
              <div class="card-actions">
                <button @click="showMoveSessionDialog(app)" class="btn">
                  Change Session
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Session Management Tab -->
        <div v-if="activeTab === 'sessions'" class="tab-content">
          <div class="tab-header">
            <h2>Session Management</h2>
          </div>

          <div class="sessions-grid">
            <div v-for="session in sessions" :key="session.id" class="session-card">
              <div class="session-header">
                <h3>{{ session.name }}</h3>
                <span class="capacity-badge" :class="{ full: session.isFull }">
                  {{ session.registered }}/{{ session.capacity }}
                </span>
              </div>
              <div class="session-content">
                <div class="info-row">
                  <span class="label">Date:</span>
                  <span>{{ session.date }}</span>
                </div>
                <div class="info-row">
                  <span class="label">Location:</span>
                  <span>{{ session.location }}</span>
                </div>
                <div class="info-row">
                  <span class="label">Status:</span>
                  <span :class="session.isFull ? 'text-danger' : 'text-success'">
                    {{ session.isFull ? 'Full' : 'Available' }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Move Session Dialog -->
    <div v-if="showMoveSession" class="modal">
      <div class="modal-content">
        <div class="modal-header">
          <h3>Change Session</h3>
          <button @click="closeMoveSessionDialog" class="close-btn">&times;</button>
        </div>
        <div class="modal-body">
          <p>Move <strong>{{ selectedApplication?.name }}</strong> to a different session:</p>
          <div class="form-group">
            <label for="newSession">Select New Session</label>
            <select id="newSession" v-model="newSessionId" class="form-control">
              <option v-for="session in availableSessions" 
                      :key="session.id" 
                      :value="session.id"
                      :disabled="session.isFull">
                {{ session.name }} ({{ session.date }})
                {{ session.isFull ? '- Full' : '' }}
              </option>
            </select>
          </div>
        </div>
        <div class="modal-footer">
          <button @click="moveToSession" class="btn" :disabled="!newSessionId">
            Confirm Move
          </button>
          <button @click="closeMoveSessionDialog" class="btn secondary">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

function getApiUrl() {
  const hostname = window.location.hostname;
  return `http://${hostname}:5000`;
}

export default {
  name: 'AdminDashboardView',
  data() {
    return {
      activeTab: 'pending',
      loading: true,
      error: null,
      pendingApplications: [],
      approvedApplications: [],
      sessions: [],
      showMoveSession: false,
      selectedApplication: null,
      newSessionId: null
    };
  },
  computed: {
    availableSessions() {
      return this.sessions.filter(s => !s.isFull || 
        (this.selectedApplication && this.selectedApplication.sessionId === s.id));
    }
  },
  created() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.loading = true;
      this.error = null;
      
      try {
        const apiUrl = getApiUrl();
        const token = localStorage.getItem('admin_token');
        
        if (!token) {
          throw new Error('Not authenticated');
        }
        
        const headers = { Authorization: `Bearer ${token}` };
        
        // Load applications
        const [applicationsRes, sessionsRes] = await Promise.all([
          axios.get(`${apiUrl}/api/admin/applications`, { headers }),
          axios.get(`${apiUrl}/api/admin/sessions`, { headers })
        ]);
        
        this.pendingApplications = applicationsRes.data.filter(app => app.status === 'pending');
        this.approvedApplications = applicationsRes.data.filter(app => app.status === 'approved');
        this.sessions = sessionsRes.data;
      } catch (error) {
        console.error('Error loading admin data:', error);
        this.error = 'Failed to load data. Please try again.';
        
        if (error.response?.status === 401) {
          this.$router.push('/admin/login');
        }
      } finally {
        this.loading = false;
      }
    },
    
    async approveApplication(application) {
      try {
        const apiUrl = getApiUrl();
        const token = localStorage.getItem('admin_token');
        
        await axios.post(
          `${apiUrl}/api/admin/applications/${application.confirmation_number}/approve`,
          {},
          { headers: { Authorization: `Bearer ${token}` } }
        );
        
        // Refresh data after approval
        await this.loadData();
      } catch (error) {
        console.error('Error approving application:', error);
        alert('Failed to approve application. Please try again.');
      }
    },
    
    async rejectApplication(application) {
      if (!confirm(`Are you sure you want to reject ${application.name}'s application?`)) {
        return;
      }
      
      try {
        const apiUrl = getApiUrl();
        const token = localStorage.getItem('admin_token');
        
        await axios.post(
          `${apiUrl}/api/admin/applications/${application.confirmation_number}/reject`,
          {},
          { headers: { Authorization: `Bearer ${token}` } }
        );
        
        // Refresh data after rejection
        await this.loadData();
      } catch (error) {
        console.error('Error rejecting application:', error);
        alert('Failed to reject application. Please try again.');
      }
    },
    
    showMoveSessionDialog(application) {
      this.selectedApplication = application;
      this.newSessionId = application.sessionId;
      this.showMoveSession = true;
    },
    
    closeMoveSessionDialog() {
      this.showMoveSession = false;
      this.selectedApplication = null;
      this.newSessionId = null;
    },
    
    async moveToSession() {
      if (!this.selectedApplication || !this.newSessionId) return;
      
      try {
        const apiUrl = getApiUrl();
        const token = localStorage.getItem('admin_token');
        
        await axios.post(
          `${apiUrl}/api/admin/applications/${this.selectedApplication.confirmation_number}/move-session`,
          { sessionId: this.newSessionId },
          { headers: { Authorization: `Bearer ${token}` } }
        );
        
        // Close dialog and refresh data
        this.closeMoveSessionDialog();
        await this.loadData();
      } catch (error) {
        console.error('Error moving to new session:', error);
        alert('Failed to move to new session. Please try again.');
      }
    },
    
    refreshData() {
      this.loadData();
    }
  }
};
</script>

<style scoped>
.admin-dashboard {
  display: flex;
  min-height: 100vh;
  background-color: #f5f5f5;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
}

/* Left Panel */
.left-panel {
  width: 250px;
  background-color: #2c3e50;
  color: #f5f5f5;
  display: flex;
  flex-direction: column;
}

.logo-container {
  display: flex;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.cf-logo {
  width: 36px;
  height: 36px;
  border-radius: 6px;
}

.logo-text {
  margin-left: 12px;
}

.logo-text h3 {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  line-height: 1.2;
}

.logo-text p {
  font-size: 12px;
  margin: 0;
  opacity: 0.7;
}

.panel-nav {
  display: flex;
  flex-direction: column;
  padding: 15px;
  flex-grow: 1;
}

.nav-btn {
  background: none;
  border: none;
  text-align: left;
  padding: 12px;
  margin-bottom: 5px;
  color: rgba(255, 255, 255, 0.7);
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
  font-size: 14px;
}

.nav-btn:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: #f5f5f5;
}

.nav-btn.active {
  background-color: rgba(255, 255, 255, 0.15);
  color: white;
  font-weight: 500;
}

/* Main Content Area */
.main-content {
  flex: 1;
  padding: 30px;
  overflow-y: auto;
}

.tab-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}

.tab-header h2 {
  font-size: 24px;
  font-weight: 500;
  color: #2c3e50;
  margin: 0;
}

/* Application Cards */
.applications-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.application-card {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  overflow: hidden;
}

.card-header {
  padding: 15px;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-header h3 {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
}

.card-content {
  padding: 15px;
}

.info-row {
  display: flex;
  margin-bottom: 8px;
}

.info-row .label {
  width: 100px;
  color: #666;
  font-size: 13px;
}

.card-actions {
  padding: 15px;
  border-top: 1px solid #eee;
  display: flex;
  gap: 10px;
}

/* Session Cards */
.sessions-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.session-card {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  overflow: hidden;
}

.session-header {
  padding: 15px;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.capacity-badge {
  background-color: #e8f5e9;
  color: #2e7d32;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
}

.capacity-badge.full {
  background-color: #ffebee;
  color: #c62828;
}

/* Status Badges */
.status-badge {
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
}

.status-badge.pending {
  background-color: #fff8e1;
  color: #f57f17;
}

.status-badge.approved {
  background-color: #e8f5e9;
  color: #2e7d32;
}

/* Buttons */
.btn {
  display: inline-block;
  background-color: #3498db;
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s;
}

.btn:hover {
  background-color: #2980b9;
}

.btn.secondary {
  background-color: #95a5a6;
}

.btn.secondary:hover {
  background-color: #7f8c8d;
}

.btn.success {
  background-color: #2ecc71;
}

.btn.success:hover {
  background-color: #27ae60;
}

.btn.danger {
  background-color: #e74c3c;
}

.btn.danger:hover {
  background-color: #c0392b;
}

/* Modal */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-header {
  padding: 15px 20px;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-header h3 {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
}

.close-btn {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
}

.modal-body {
  padding: 20px;
}

.modal-footer {
  padding: 15px 20px;
  border-top: 1px solid #eee;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

/* Form Controls */
.form-control {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

/* Loading State */
.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 15px;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

/* Error Message */
.error-message {
  background-color: #ffebee;
  color: #c62828;
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 20px;
  text-align: center;
}

/* Responsive Design */
@media (max-width: 768px) {
  .admin-dashboard {
    flex-direction: column;
  }
  
  .left-panel {
    width: 100%;
  }
  
  .panel-nav {
    flex-direction: row;
    padding: 10px;
  }
  
  .nav-btn {
    flex: 1;
    text-align: center;
    padding: 8px;
  }
  
  .main-content {
    padding: 15px;
  }
  
  .applications-grid,
  .sessions-grid {
    grid-template-columns: 1fr;
  }
}
</style> 