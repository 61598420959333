<template>
  <div class="rounded-md shadow-sm border-0 mb-4 overflow-hidden bg-white">
    <div class="bg-gray-100 border-b-0 flex items-center p-4">
      <i class="bi bi-people-fill me-2 text-secondary"></i>
      <h3 class="mb-0 text-xl font-medium text-secondary">Camp Directors</h3>
    </div>
    <div class="p-5">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <!-- Judy Stroud -->
        <div class="md:col-span-1">
          <div class="p-5 bg-white rounded-md border-l-3 border-l-secondary/25 border-solid h-full transition-all duration-200 hover:shadow-md hover:-translate-y-0.5">
            <div class="flex flex-col md:flex-row items-center md:items-start text-center md:text-left">
              <div class="flex-shrink-0 mb-4 md:mb-0 md:mr-5">
                <img src="/img/judy.jpg" alt="Judy Stroud" class="w-20 h-20 rounded-full object-cover shadow-sm transition-all duration-300 hover:scale-105 hover:shadow-md mx-auto md:mx-0" />
              </div>
              <div class="flex-1">
                <h4 class="text-lg font-semibold text-gray-700 mb-1">Judy Stroud</h4>
                <p class="text-sm text-gray-500 mb-3 pb-3 border-b border-gray-100">Camp Director</p>
                <div class="text-[0.92rem] text-gray-700 leading-relaxed">
                  <p class="mb-2">The Associate Coordinator of Women's Basketball Officials, Big South Conference, Coastal Athletic Association, South Atlantic Conference, and formerly the Southern Conference.</p>
                  <p class="mb-0">Stroud was a former Collegiate Official in NCAA Division I, II, and NAIA, working Championship games at all levels and she also was one of the 24 inaugural officials of the WNBA. Stroud was also one of the original regional advisors for the NCAA serving in that capacity for 13 years.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <!-- Tommy Salerno -->
        <div class="md:col-span-1">
          <div class="p-5 bg-white rounded-md border-l-3 border-l-secondary/25 border-solid h-full transition-all duration-200 hover:shadow-md hover:-translate-y-0.5">
            <div class="flex flex-col md:flex-row items-center md:items-start text-center md:text-left">
              <div class="flex-shrink-0 mb-4 md:mb-0 md:mr-5">
                <img src="/img/tommy.jpg" alt="Tommy Salerno" class="w-20 h-20 rounded-full object-cover shadow-sm transition-all duration-300 hover:scale-105 hover:shadow-md mx-auto md:mx-0" />
              </div>
              <div class="flex-1">
                <h4 class="text-lg font-semibold text-gray-700 mb-1">Tommy Salerno</h4>
                <p class="text-sm text-gray-500 mb-3 pb-3 border-b border-gray-100">Camp Director</p>
                <div class="text-[0.92rem] text-gray-700 leading-relaxed">
                  <p class="mb-2">Selected to work the NCAA Division I National Tournament for 29 years, including 6 regionals and the 1984 Final Four. He is the recipient if the 2008 Naismith Award and has been selected to work the ACC, Metro, Sunbelt, Colonial, Southern, and Conference USA post season tournaments.</p>
                  <p class="mb-0">He worked on staff in the ACC, Big East, Big 12, SEC, Metro, Conference USA, A-10, Colonial, Sunbelt, Southland, Big South, MEAC, and Southern conferences during his 45 year career. He is presently the supervisor of the Div. II Carolinas Conference, the Div. III USA South Athletic Conference, the Region X Jr. College Conference, and is the assistant supervisor of the Atlantic Coast Conference.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CampInfo'
}
</script> 