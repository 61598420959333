<template>
  <div class="min-h-screen bg-gray-50">
    <PageHeader />
    
    <!-- Hero Section - With background pattern instead of image -->
    <transition name="slide-fade" mode="out-in">
      <div v-if="!showRegistrationForm" class="bg-gray-900 text-white py-20 relative overflow-hidden" key="hero-section">
        <!-- Background pattern -->
        <div class="absolute inset-0 opacity-10">
          <div class="absolute inset-0" style="background-image: url('data:image/svg+xml,%3Csvg width=\'20\' height=\'20\' viewBox=\'0 0 20 20\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cg fill=\'%23ffffff\' fill-opacity=\'1\' fill-rule=\'evenodd\'%3E%3Ccircle cx=\'3\' cy=\'3\' r=\'1\'/%3E%3Ccircle cx=\'13\' cy=\'13\' r=\'1\'/%3E%3C/g%3E%3C/svg%3E'); background-size: 20px 20px;"></div>
        </div>
        
        <div class="container mx-auto px-6 relative z-10">
          <div class="max-w-3xl mx-auto text-center">
            <h1 class="text-4xl md:text-5xl font-light mb-6 leading-tight">Carolina's Finest <span class="font-semibold">Basketball Camp</span></h1>
            <p class="text-lg text-gray-300 mb-10 mx-auto">Professional development for basketball officials taught by industry-leading instructors</p>
            <div class="flex flex-wrap gap-5 justify-center">
              <button @click="openRegistrationForm" class="px-6 py-3 bg-white text-gray-900 rounded hover:bg-gray-200 transition-colors duration-200 font-medium flex items-center">
                <i class="bi bi-pencil-square me-2"></i>
                Register Now
              </button>
              <button @click="showMap = true" class="px-6 py-3 border border-white text-white rounded hover:bg-white/10 transition-colors duration-200 flex items-center">
                <i class="bi bi-geo-alt me-2"></i>
                View Location
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>
    
    <!-- Main Content -->
    <div class="container mx-auto px-6 py-16">
      <!-- Session Cards - More minimal design -->
      <transition name="slide-fade" mode="out-in">
        <div v-if="!showRegistrationForm" class="mb-24" key="camp-sessions">
          <div class="mb-16">
            <h2 class="text-2xl font-light text-gray-900 mb-3">Available <span class="font-medium">Camp Sessions</span></h2>
            <div class="w-16 h-1 bg-gray-900"></div>
          </div>
          
          <!-- Location and Date Change Alert -->
          <div class="mb-8 p-4 bg-blue-50 border-l-4 border-blue-500 text-blue-800 rounded">
            <div class="flex">
              <div class="flex-shrink-0">
                <i class="bi bi-info-circle-fill text-blue-500 text-xl mr-3"></i>
              </div>
              <div>
                <p class="font-medium">Important Update</p>
                <p class="mt-1">Please note that our 2025 camp location and dates have changed. The camp will now be held at <strong>Rock Hill Sports and Event Center from May 2nd - 4th, 2025</strong>.</p>
              </div>
            </div>
          </div>
          
          <!-- Loading state -->
          <div v-if="loading" class="p-8 text-center">
            <div class="inline-block animate-spin rounded-full h-8 w-8 border-2 border-gray-900 border-t-transparent"></div>
            <p class="mt-4 text-gray-600">Loading available camps...</p>
          </div>
          
          <!-- Error state -->
          <div v-else-if="error" class="max-w-xl mx-auto p-6 bg-red-50 border-l-4 border-red-500 text-red-700">
            <p class="flex items-center">
              <i class="bi bi-exclamation-triangle-fill me-2"></i>
              {{ error }}
            </p>
          </div>
          
          <!-- No camps found -->
          <div v-else-if="camps.length === 0" class="max-w-xl mx-auto p-6 bg-gray-50 border border-gray-200 rounded">
            <p class="text-gray-600 text-center">No camps are currently available.</p>
          </div>
          
          <!-- Camps list - Full-width design -->
          <div v-else class="grid grid-cols-1 gap-8">
            <div v-for="camp in camps" :key="camp.id" class="group">
              <div 
                :class="[
                  'flex flex-col md:flex-row bg-white border rounded overflow-hidden transition-all duration-300',
                  camp.isFull ? 'border-gray-300 opacity-75' : 'border-gray-200 hover:border-gray-900'
                ]"
              >
                <!-- Camp Info Section -->
                <div class="p-6 border-b md:border-b-0 md:border-r border-gray-100 md:w-1/3 relative">
                  <h3 class="text-2xl font-medium text-gray-900 mb-2">{{ camp.name }}</h3>
                  <p class="text-gray-600 mb-4">Join us for a comprehensive basketball officiating camp focused on skill development and professional growth.</p>
           
                  <!-- Full session badge -->
                  <div v-if="camp.isFull" class="absolute top-2 right-2 bg-red-100 text-red-800 text-xs font-medium px-2 py-1 rounded">
                    FULL
                  </div>
                </div>
                
                <!-- Camp Details Section -->
                <div class="p-6 flex-1 flex flex-col">
                  <div class="grid grid-cols-1 md:grid-cols-3 gap-6">
                    <!-- Left column - Dates and basic info -->
                    <div>
                      <ul class="space-y-5">
                        <li class="flex items-center">
                          <i class="bi bi-calendar-check text-gray-500 text-lg mr-3"></i>
                          <div>
                            <p class="text-xs text-gray-500 uppercase tracking-wider">Dates</p>
                            <p class="font-medium text-gray-900">{{ camp.dates }}</p>
                          </div>
                        </li>
                        <li class="flex items-center">
                          <i class="bi bi-clock text-gray-500 text-lg mr-3"></i>
                          <div>
                            <p class="text-xs text-gray-500 uppercase tracking-wider">Times</p>
                            <p class="font-medium text-gray-900">{{ camp.times }}</p>
                          </div>
                        </li>
                        <li class="flex items-center">
                          <i class="bi bi-currency-dollar text-gray-500 text-lg mr-3"></i>
                          <div>
                            <p class="text-xs text-gray-500 uppercase tracking-wider">Cost</p>
                            <p class="font-medium text-gray-900">${{ camp.cost }}</p>
                          </div>
                        </li>
                      </ul>
                    </div>
                    
                    <!-- Middle column - Location -->
                    <div>
                      <ul class="space-y-5">
                        <li class="flex items-start">
                          <i class="bi bi-geo-alt text-gray-500 text-lg mr-3 mt-1"></i>
                          <div>
                            <p class="text-xs text-gray-500 uppercase tracking-wider">Location</p>
                            <p class="font-medium text-gray-900">Rock Hill Sports and Event Center</p>
                            <p class="text-xs text-gray-500">326 Technology Center Way</p>
                            <p class="text-xs text-gray-500">Rock Hill, SC 29730</p>
                          </div>
                        </li>
                      </ul>
                    </div>
                    
                    <!-- Right column - Requirements -->
                    <div>
                      <div class="flex items-start">
                        <i class="bi bi-clipboard-check text-gray-500 text-lg mr-3 mt-1"></i>
                        <div>
                          <p class="text-xs text-gray-500 uppercase tracking-wider mb-2">Requirements</p>
                          
                          <!-- When camp.requirements exists (as array) -->
                          <ul v-if="camp.requirements && Array.isArray(camp.requirements) && camp.requirements.length > 0" class="space-y-2">
                            <li v-for="(requirement, index) in camp.requirements" :key="index" class="flex items-start">
                              <i class="bi bi-check2 text-green-600 mt-0.5 mr-2 flex-shrink-0 text-xs"></i>
                              <span class="text-sm text-gray-700">{{ requirement }}</span>
                            </li>
                          </ul>
                          
                          <!-- Fallback to hardcoded requirements -->
                          <ul v-else class="space-y-2">
                            <li class="flex items-start">
                              <i class="bi bi-check2 text-green-600 mt-0.5 mr-2 flex-shrink-0 text-xs"></i>
                              <span class="text-sm text-gray-700">Full NCAA WBB Official Attire</span>
                            </li>
                            <li class="flex items-start">
                              <i class="bi bi-check2 text-green-600 mt-0.5 mr-2 flex-shrink-0 text-xs"></i>
                              <span class="text-sm text-gray-700">Lodging on your own</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <button 
                    @click="camp.isFull ? null : openRegistrationForm(camp)" 
                    :class="[
                      'w-full py-3 rounded transition-colors duration-200 mt-8',
                      camp.isFull 
                        ? 'bg-gray-300 text-gray-500 cursor-not-allowed' 
                        : 'bg-gray-900 text-white hover:bg-gray-800'
                    ]"
                    :disabled="camp.isFull"
                  >
                    {{ camp.isFull ? 'Session Full' : 'Register Now' }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
      
      <!-- Registration Form (shown/hidden based on state) -->
      <transition name="slide-fade" mode="out-in">
        <div v-if="showRegistrationForm" class="mb-24" key="registration-form">
          <div class="max-w-5xl mx-auto bg-white border border-gray-200 rounded shadow-sm overflow-hidden">
            <div class="flex justify-between items-center p-6 border-b border-gray-200 bg-gray-50">
              <h2 class="text-xl font-medium text-gray-900 mb-0 flex items-center">
                <i class="bi bi-pencil-square me-3 text-gray-500"></i>
                Registration Form
              </h2>
              <button @click="closeRegistrationForm" class="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300 transition-colors duration-200 text-gray-700">
                <i class="bi bi-arrow-left me-2"></i> Back
              </button>
            </div>
            <div class="p-6">
              <RegistrationForm :available-camps="camps" :selected-camp="formattedSelectedCamp" />
            </div>
          </div>
        </div>
      </transition>
      
      <!-- Location & Details Section - More professional layout -->
      <transition name="slide-fade" mode="out-in">
        <div v-if="!showRegistrationForm" class="mb-24" key="camp-details">
          <div class="mb-16">
            <h2 class="text-2xl font-light text-gray-900 mb-3">Camp <span class="font-medium">Details</span></h2>
            <div class="w-16 h-1 bg-gray-900"></div>
          </div>
          
          <div class="grid grid-cols-1 lg:grid-cols-2 gap-12">
            <!-- Location Card -->
            <div class="bg-white border border-gray-200 rounded overflow-hidden">
              <div class="relative">
                <img src="@/assets/rh_center.jpg" alt="Lake Point Academy" class="w-full h-64 object-cover" />
                <div class="absolute inset-0 bg-gradient-to-t from-black/80 to-transparent flex items-end">
                  <div class="p-6 text-white">
                    <h3 class="text-xl font-medium mb-1">Rock Hill Sports and Event Center</h3>
                    <p class="mb-4 text-gray-200">326 Technology Center Way<br>
                    Rock Hill, SC 29730</p>
                  </div>
                </div>
              </div>
              <div class="p-6 space-y-6">
                <div>
                  <h4 class="text-sm uppercase tracking-wider text-gray-500 mb-3">Location</h4>
                  <address class="not-italic text-gray-700">
                    <strong>Rock Hill Sports and Event Center</strong><br>
                    326 Technology Center Way<br>
                    Rock Hill, SC 29730
                  </address>
                  <button @click="showMap = true" class="mt-4 px-4 py-2 border border-gray-900 text-gray-900 rounded hover:bg-gray-900 hover:text-white transition-colors duration-200 text-sm">
                    <i class="bi bi-geo-alt me-2"></i>View on Map
                  </button>
                </div>
                
                <div>
                  <h4 class="text-sm uppercase tracking-wider text-gray-500 mb-3">Requirements</h4>
                  <ul class="space-y-2 text-gray-700">
                    <li class="flex items-start">
                      <i class="bi bi-building text-gray-500 mt-1 mr-3"></i>
                      <div>
                        <p class="font-medium">Accommodations</p>
                        <p class="text-gray-600">Lodging on your own</p>
                      </div>
                    </li>
                    <li class="flex items-start">
                      <i class="bi bi-person-badge text-gray-500 mt-1 mr-3"></i>
                      <div>
                        <p class="font-medium">Attire</p>
                        <p class="text-gray-600">Full NCAA WBB Official Attire - no shorts</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            
            <!-- Camp Directors & Contact -->
            <div class="space-y-8">
              <!-- Camp Directors - More minimal presentation -->
              <div class="bg-white border border-gray-200 rounded overflow-hidden">
                <div class="p-6 border-b border-gray-100">
                  <h3 class="text-xl font-medium text-gray-900">Camp Directors</h3>
                </div>
                <div class="p-6 space-y-8">
                  <!-- Judy Stroud -->
                  <div class="flex items-start">
                    <img src="/img/judy.jpg" alt="Judy Stroud" class="w-16 h-16 rounded-full object-cover border border-gray-200 mr-5" />
                    <div>
                      <h4 class="text-lg font-medium text-gray-900">Judy Stroud</h4>
                      <p class="text-gray-500 text-sm mb-3">Camp Director</p>
                      <p class="text-gray-700">The Associate Coordinator of Women's Basketball Officials, Big South Conference,
                        Coastal Athletic Association, South Atlantic Conference, and formerly the Southern Conference.
                        Stroud was a
                        former Collegiate Official in NCAA Division I, II, and NAIA, working Championship games at all
                        levels and she also was one of the 24 inaugural officials of the WNBA. Stroud was also one of
                        the original regional advisors for the NCAA serving in that capacity for 13 years.</p>
                    </div>
                  </div>
                  
                  <div class="w-full h-px bg-gray-100"></div>
                  
                  <!-- Tommy Salerno -->
                  <div class="flex items-start">
                    <img src="/img/tommy.jpg" alt="Tommy Salerno" class="w-16 h-16 rounded-full object-cover border border-gray-200 mr-5" />
                    <div>
                      <h4 class="text-lg font-medium text-gray-900">Tommy Salerno</h4>
                      <p class="text-gray-500 text-sm mb-3">Camp Director</p>
                      <p class="text-gray-700">Selected to work the NCAA Division I National Tournament for 29 years, including 6
                        regionals and the 1984 Final Four. He is the recipient if the 2008 Naismith Award and has been
                        selected to work the ACC, Metro, Sunbelt, Colonial, Southern, and Conference USA post season
                        tournaments. He worked on staff in the ACC, Big East, Big 12, SEC, Metro, Conference USA, A-10,
                        Colonial, Sunbelt, Southland, Big South, MEAC, and Southern conferences during his 45 year
                        career. He is presently the supervisor of the Div. II Carolinas Conference, the Div. III USA
                        South Athletic Conference, the Region X Jr. College Conference, and is the assistant supervisor
                        of the Atlantic Coast Conference. He serves on the NCAA mechanics committee and was one of the
                        directors of the first women's officiating camps in 1984. He has served on numerous camps around
                        the nation.</p>
                    </div>
                  </div>
                </div>
              </div>
              
              <!-- Contact Section - Simple, professional design -->
              <div class="bg-white border border-gray-200 rounded overflow-hidden">
                <div class="p-6 border-b border-gray-100">
                  <h3 class="text-xl font-medium text-gray-900 flex items-center">
                    <i class="bi bi-question-circle me-2 text-gray-500"></i>
                    Questions?
                  </h3>
                </div>
                <div class="p-6 text-center">
                  <p class="mb-6 text-gray-700">If you have any questions about the camp, please don't hesitate to contact us.</p>
                  <button class="px-6 py-3 bg-gray-900 text-white rounded hover:bg-gray-800 transition-colors duration-200">
                    <i class="bi bi-envelope-fill me-2"></i>
                    Contact Us
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    
    <!-- Map Modal - More minimal design -->
    <div v-if="showMap" class="fixed inset-0 bg-black/60 flex items-center justify-center z-50" @click="showMap = false">
      <div class="max-w-3xl w-full mx-4 bg-white rounded shadow-lg overflow-hidden" @click.stop>
        <div class="p-4 flex items-center justify-between border-b border-gray-200">
          <h5 class="text-lg font-medium text-gray-900 mb-0">Rock Hill Sports and Event Center</h5>
          <button @click="showMap = false" class="w-8 h-8 flex items-center justify-center rounded-full hover:bg-gray-100 transition-colors duration-200">
            <i class="bi bi-x"></i>
          </button>
        </div>
        <div class="p-4">
          <div class="mb-4 flex items-center text-gray-700">
            <i class="bi bi-geo-alt me-2 text-gray-500"></i>
            <span class="font-medium">326 Technology Center Way, Rock Hill, SC 29730</span>
          </div>
          <div class="w-full rounded overflow-hidden border border-gray-200">
            <iframe 
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3305.7168181492364!2d-81.1630206!3d34.9215456!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88569b0999999999%3A0x8f8f8f8f8f8f8f8f!2sRock%20Hill%20Sports%20and%20Event%20Center!5e0!3m2!1sen!2sus!4v1715736000000!5m2!1sen!2sus" 
              width="100%" 
              height="400" 
              style="border:0;" 
              allowfullscreen="" 
              loading="lazy">
            </iframe>
          </div>
        </div>
        <div class="p-4 bg-gray-50 border-t border-gray-200 flex justify-end gap-3">
          <button @click="showMap = false" class="px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded hover:bg-gray-100 transition-colors duration-200">
            Close
          </button>
          <a href="https://maps.google.com/?q=Rock+Hill+Sports+and+Event+Center+326+Technology+Center+Way+Rock+Hill+SC+29730" target="_blank" class="px-4 py-2 bg-gray-900 text-white rounded hover:bg-gray-800 transition-colors duration-200">
            Open in Google Maps
          </a>
        </div>
      </div>
    </div>
    
    <PageFooter />
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader.vue'
import CampInfo from '@/components/CampInfo.vue'
import RegistrationForm from '@/components/RegistrationForm.vue'
import PageFooter from '@/components/PageFooter.vue'
import axios from 'axios'

export default {
  name: 'Home',
  components: {
    PageHeader,
    CampInfo,
    RegistrationForm,
    PageFooter
  },
  data() {
    return {
      showRegistrationForm: false,
      showMap: false,
      camps: [],
      loading: true,
      error: null,
      selectedCamp: null
    }
  },
  mounted() {
    this.fetchCamps()
  },
  computed: {
    // Convert the camp object to the format expected by the RegistrationForm
    formattedSelectedCamp() {
      if (!this.selectedCamp) return null;
      
      return {
        id: this.selectedCamp.id,
        name: this.selectedCamp.name,
        dates: this.selectedCamp.dates,
        cost: this.selectedCamp.cost
      };
    }
  },
  methods: {
    async fetchCamps() {
      try {
        this.loading = true
        console.log('Fetching camps from API...')
        
        // Construct API URL based on environment
        let apiUrl = '/api/camps'
        if (process.env.NODE_ENV === 'development') {
          // In development, try the direct URL if proxy fails
          try {
            const response = await axios.get(apiUrl)
            console.log('API response via proxy:', response.data)
            this.camps = response.data
            this.loading = false
            return
          } catch (proxyError) {
            console.warn('Proxy request failed, trying direct URL:', proxyError)
            // Try direct URL as fallback
            apiUrl = 'http://localhost:5000/api/camps'
          }
        }
        
        // Make the API request
        console.log(`Making API request to: ${apiUrl}`)
        const response = await axios.get(apiUrl, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        })
        
        console.log('API response:', response.data)
        this.camps = response.data
        this.loading = false
      } catch (error) {
        console.error('Error fetching camps:', error)
        
        // More detailed error logging
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.error('Error data:', error.response.data)
          console.error('Error status:', error.response.status)
          console.error('Error headers:', error.response.headers)
          this.error = `Server error: ${error.response.status} - ${error.response.data?.detail || 'Unknown error'}`
        } else if (error.request) {
          // The request was made but no response was received
          console.error('No response received:', error.request)
          this.error = 'Failed to connect to the server. Please check your network connection.'
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error('Error message:', error.message)
          this.error = `Error: ${error.message}`
        }
        
        this.loading = false
        
        // Use a single camp session as fallback
        console.log('Using fallback camp data - single session')
        this.camps = [
          {
            "_id": "67c1c45973878984da6ec096",
            "id": "CFC_2025_S1",
            "name": "Carolina's Finest Basketball Camp 2025",
            "max": 70,
            "dates": "May 2nd - May 4th, 2025",
            "times": "9:00 AM - 5:00 PM",
            "cost": 300,
            "enrolled": 45,
            "isFull": false,
            "requirements": [
              "Full NCAA WBB Official Attire - no shorts",
              "Lodging on your own",
              "All experience levels welcome",
              "Whistle and proper officiating equipment required"
            ]
          }
        ]
      }
    },
    openRegistrationForm(camp) {
      // Only allow registration if the session is not full
      if (camp && camp.isFull) {
        return;
      }
      
      // Set the selected camp
      this.selectedCamp = camp;
      this.showRegistrationForm = true;
      
      // Scroll to the top
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    closeRegistrationForm() {
      this.showRegistrationForm = false
      
      // A small delay to ensure transitions complete before scrolling
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      }, 100)
    }
  }
}
</script>

<style>
/* Transition effects */
.slide-fade-enter-active, .slide-fade-leave-active {
  transition: all 0.4s ease;
}
.slide-fade-enter-from, .slide-fade-leave-to {
  transform: translateY(20px);
  opacity: 0;
}
</style>
