<template>
  <router-view />
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,700;1,800;1,900&family=Roboto:wght@500&family=Rubik:ital,wght@0,400;0,500;0,700;0,800;0,900;1,300;1,400;1,800;1,900&display=swap');

body {
  font-family: 'Noto Sans', sans-serif;
  background-color: #f8f9fa;
  color: #333;
  font-size: 0.95rem;
}
</style> 