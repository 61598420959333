<template>
  <div class="about">
    <div class="container py-5">
      <h1 class="text-center mb-4">About Carolina's Finest Camp</h1>
      
      <div class="card shadow-sm">
        <div class="card-body">
          <h2 class="card-title">Our Mission</h2>
          <p class="card-text">
            Carolina's Finest Camp is dedicated to providing top-tier training and development 
            opportunities for basketball referees across all skill levels. Our goal is to elevate 
            officiating standards and help referees reach their full potential.
          </p>
          
          <h2 class="card-title mt-4">What We Offer</h2>
          <ul class="card-text">
            <li>Professional instruction from experienced officials</li>
            <li>Hands-on training sessions with real game scenarios</li>
            <li>Video analysis and personalized feedback</li>
            <li>Networking opportunities with peers and mentors</li>
            <li>Career advancement strategies and certification guidance</li>
          </ul>
          
          <h2 class="card-title mt-4">Contact Us</h2>
          <p class="card-text">
            For more information about our programs or registration details, please contact us at:
            <br>
            <strong>Email:</strong> info@carolinasfinestcamp.com
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutView'
}
</script>

<style scoped>
.about {
  min-height: 100vh;
  padding-top: 2rem;
}

h1 {
  color: #343a40;
  font-weight: 700;
}

h2 {
  color: #495057;
  font-size: 1.5rem;
  font-weight: 600;
}

.card {
  border-radius: 10px;
  overflow: hidden;
  border: none;
}

ul {
  padding-left: 1.5rem;
}

li {
  margin-bottom: 0.5rem;
}
</style> 