import axios from 'axios';
import { getAuthToken } from './auth';

// Determine the API base URL based on environment and hostname
const getBaseUrl = () => {
  // Get the hostname of the current app
  const hostname = window.location.hostname;
  
  if (process.env.NODE_ENV === 'production') {
    // In production, API calls are on the same origin
    return '';
  } else if (hostname === '10.0.1.100' || hostname === 'localhost') {
    // When running on specific hosts
    return 'http://10.0.1.100:5000';
  } else {
    // Default backend URL
    return 'http://10.0.1.100:5000';
  }
};

// Create API instance with proper configuration
const api = axios.create({
  baseURL: getBaseUrl(),
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
  withCredentials: false, // Set to false for cross-origin requests without credentials
});

// Request interceptor for adding auth token
api.interceptors.request.use(config => {
  const token = getAuthToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
    console.log(`Adding auth token to request: ${config.url}`);
  } else {
    console.log(`No auth token available for request: ${config.url}`);
  }
  
  console.log(`API Request: ${config.method.toUpperCase()} ${config.url}`, config.data);
  return config;
});

// Response interceptor for logging
api.interceptors.response.use(
  response => {
    console.log(`API Response from ${response.config.url}:`, response.status);
    return response;
  },
  error => {
    if (error.response) {
      console.error('API Error Response:', error.response.status, error.response.data);
      
      // If unauthorized, we could handle redirecting to login here
      if (error.response.status === 401) {
        console.warn('Authentication error - consider redirecting to login');
        // Could redirect to login if needed: window.location.href = '/login';
      }
    } else if (error.request) {
      console.error('API No Response:', error.request);
    } else {
      console.error('API Error:', error.message);
    }
    return Promise.reject(error);
  }
);

export default api; 