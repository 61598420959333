<template>
  <div class="maintenance-container">
    <div class="maintenance-card">
      <div class="logo-container">
        <img src="/img/cf_circle.png" alt="Carolina's Finest Referee Camp" class="logo" />
      </div>
      
      <div class="content">
        <h1>Site Maintenance</h1>
        
        <div class="maintenance-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <circle cx="12" cy="12" r="7"></circle>
            <polyline points="12 9 12 12 13.5 13.5"></polyline>
            <path d="M16.51 17.35l-.35 3.83a2 2 0 0 1-2 1.82H9.83a2 2 0 0 1-2-1.82l-.35-3.83m.01-10.7l.35-3.83A2 2 0 0 1 9.83 1h4.35a2 2 0 0 1 2 1.82l.35 3.83"></path>
          </svg>
        </div>
        
        <p class="message">Our website is currently undergoing scheduled maintenance and will be back soon.</p>
        
        <div class="info-box">
          <div class="info-item">
            <strong>Estimated Return:</strong>
            <span>March 14, 2025</span>
          </div>
          <div class="info-item">
            <strong>Contact:</strong>
            <span>ncaareferee12@gmail.com</span>
          </div>
        </div>
        
        <div class="updates">
          <h2>Camp Updates</h2>
          <ul>
            <li>
              <span class="update-date">March 15, 2025</span>
              <p>Registration for the 2025 camp is temporarily paused due to date and location change.</p>
            </li>
          </ul>
        </div>
      </div>
      
      <div class="footer">
        <p>Thank you for your patience. If you need immediate assistance, please email us.</p>

        <div class="admin-link">
          <router-link to="/home" class="admin-bypass">Admin Access</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MaintenancePage'
}
</script>

<style scoped>
.maintenance-container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background-color: #f8fafc;
  background-image: 
    radial-gradient(circle at 25px 25px, rgba(0, 0, 0, 0.03) 2%, transparent 2%),
    radial-gradient(circle at 50px 50px, rgba(0, 0, 0, 0.03) 2%, transparent 2%);
  background-size: 100px 100px;
}

.maintenance-card {
  width: 100%;
  max-width: 800px;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  overflow: hidden;
}

.logo-container {
  background-color: #4a6da7;
  padding: 2rem;
  text-align: center;
}

.logo {
  max-height: 60px;
}

.content {
  padding: 2rem;
}

h1 {
  text-align: center;
  color: #1e3a8a;
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  font-weight: 700;
}

.maintenance-icon {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  color: #4a6da7;
}

.message {
  text-align: center;
  font-size: 1.25rem;
  color: #475569;
  margin-bottom: 2.5rem;
  line-height: 1.6;
}

.info-box {
  background-color: #f1f5f9;
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.info-item {
  display: flex;
  flex-direction: column;
}

.info-item strong {
  font-size: 0.875rem;
  color: #64748b;
  margin-bottom: 0.25rem;
}

.info-item span {
  font-size: 1.125rem;
  color: #334155;
  font-weight: 500;
}

.updates {
  border-top: 1px solid #e2e8f0;
  padding-top: 1.5rem;
  margin-top: 1.5rem;
}

.updates h2 {
  font-size: 1.25rem;
  color: #1e3a8a;
  margin-bottom: 1rem;
  font-weight: 600;
}

.updates ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.updates li {
  padding: 1rem;
  background-color: #f8fafc;
  border-radius: 8px;
  margin-bottom: 0.75rem;
  border: 1px solid #e2e8f0;
}

.update-date {
  display: inline-block;
  font-size: 0.875rem;
  color: #64748b;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.updates li p {
  margin: 0;
  color: #334155;
  line-height: 1.5;
}

.footer {
  background-color: #f1f5f9;
  padding: 1.5rem 2rem;
  text-align: center;
  border-top: 1px solid #e2e8f0;
}

.footer p {
  color: #64748b;
  margin-bottom: 1rem;
}

.social-links {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
}

.social-link {
  color: #4a6da7;
  transition: color 0.2s;
}

.social-link:hover {
  color: #1e3a8a;
}

.admin-link {
  margin-top: 1.5rem;
  opacity: 0.5;
}

.admin-bypass {
  font-size: 0.75rem;
  color: #64748b;
  text-decoration: none;
}

.admin-bypass:hover {
  text-decoration: underline;
}

@media (min-width: 640px) {
  .info-box {
    flex-direction: row;
    justify-content: space-around;
  }
  
  .info-item {
    align-items: center;
  }
}
</style> 