<template>
  <div class="cancel-container">
    <div class="header">
      <h1>Carolina's Finest Referee Camp</h1>
      <p>Registration Cancelled</p>
    </div>
    
    <div class="cancel-icon">✕</div>
    
    <h2>Registration Process Cancelled</h2>
    
    <div class="message">
      <p>Your registration process was cancelled and no payment has been processed.</p>
      <p>If you wish to register for the camp, please return to the registration page and complete the process.</p>
    </div>
    
    <div class="actions">
      <router-link to="/" class="btn">Return to Registration</router-link>
      <a href="mailto:camp@carolinasfinestrefereecamp.com" class="btn secondary">Contact Support</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CancelView'
};
</script>

<style scoped>
.cancel-container {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  padding: 30px;
  margin: 20px auto;
  max-width: 800px;
  text-align: center;
}

.header {
  margin-bottom: 30px;
  border-bottom: 2px solid #eaeaea;
  padding-bottom: 20px;
}

.header h1 {
  color: #2c3e50;
  font-size: 28px;
  margin-bottom: 10px;
}

.cancel-icon {
  font-size: 72px;
  color: #e74c3c;
  margin-bottom: 20px;
}

.actions {
  margin-top: 30px;
}

.btn {
  display: inline-block;
  background-color: #3498db;
  color: white;
  padding: 12px 25px;
  text-decoration: none;
  border-radius: 4px;
  font-weight: bold;
  margin: 10px 5px;
}

.btn:hover {
  background-color: #2980b9;
}

.btn.secondary {
  background-color: #95a5a6;
}

.btn.secondary:hover {
  background-color: #7f8c8d;
}

.message {
  max-width: 600px;
  margin: 0 auto 30px auto;
}
</style> 