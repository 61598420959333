<template>
  <div class="admin-login-container">
    <div class="header">
      <h1>Carolina's Finest Referee Camp</h1>
      <p>Admin Portal</p>
    </div>
    
    <div class="login-form">
      <div class="error-message" v-if="error">
        {{ error }}
      </div>
      
      <div class="success-message" v-if="showSuccessMessage">
        Login successful! Redirecting to admin dashboard...
      </div>
      
      <form @submit.prevent="handleLogin">
        <div class="form-group">
          <label for="email">Admin Email</label>
          <input type="email" id="email" v-model="email" required>
        </div>
        
        <div class="form-group">
          <label for="password">Password</label>
          <input type="password" id="password" v-model="password" required>
        </div>
        
        <button type="submit" class="btn" :disabled="loading || showSuccessMessage">
          {{ loading ? 'Logging in...' : 'Login' }}
        </button>
      </form>
      
      <div class="help-text">
        <p>Admin access is restricted to authorized personnel only.</p>
        <p>If you need assistance, please contact <a href="mailto:camp@carolinasfinestrefereecamp.com">camp@carolinasfinestrefereecamp.com</a></p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

// Helper to get the API URL based on current hostname
function getApiUrl() {
  const hostname = window.location.hostname;
  return `http://${hostname}:5000`;
}

export default {
  name: 'AdminLoginView',
  data() {
    return {
      email: '',
      password: '',
      loading: false,
      error: null,
      showSuccessMessage: false
    };
  },
  methods: {
    async handleLogin() {
      this.loading = true;
      this.error = null;
      
      try {
        console.log('Starting admin login process...');
        
        const credentials = {
          email: this.email,
          password: this.password,
          admin_login: true
        };
        
        // Validate inputs
        if (!credentials.email) {
          throw new Error('Email address is required');
        }
        
        if (!credentials.password) {
          throw new Error('Password is required');
        }
        
        console.log('Attempting admin login');
        
        // Request a token with admin flag
        const apiUrl = getApiUrl();
        const tokenResponse = await axios.post(`${apiUrl}/token`, credentials);
        
        if (!tokenResponse.data.access_token) {
          throw new Error('Authentication failed');
        }
        
        // Check if user is admin
        if (!tokenResponse.data.is_admin) {
          throw new Error('You do not have administrator privileges');
        }
        
        // Store the admin token and details
        localStorage.setItem('admin_token', tokenResponse.data.access_token);
        localStorage.setItem('admin_user', JSON.stringify({
          email: this.email,
          is_admin: true
        }));
        
        console.log('Admin login successful, redirecting to admin dashboard');
        
        // Show success message before redirecting
        this.showSuccessMessage = true;
        
        // Redirect to admin dashboard after a brief delay
        setTimeout(() => {
          this.$router.push({ name: 'admin-dashboard' });
        }, 1000);
      } catch (error) {
        console.error('Admin login error:', error);
        
        if (error.response && error.response.data) {
          this.error = error.response.data.detail || error.response.data.message || 'Admin login failed';
        } else {
          this.error = error.message || 'Admin login failed. Please check your credentials.';
        }
        
        console.error('Admin login failed:', {
          email: this.email,
          error: this.error,
          timestamp: new Date().toISOString()
        });
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped>
.admin-login-container {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  padding: 30px;
  margin: 20px auto;
  max-width: 800px;
}

.header {
  text-align: center;
  margin-bottom: 30px;
  border-bottom: 2px solid #eaeaea;
  padding-bottom: 20px;
}

.header h1 {
  color: #2c3e50;
  font-size: 28px;
  margin-bottom: 10px;
}

.header p {
  font-weight: 500;
  color: #3498db;
  font-size: 18px;
}

.login-form {
  max-width: 500px;
  margin: 0 auto;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

input[type="email"],
input[type="password"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.btn {
  display: inline-block;
  background-color: #3498db;
  color: white;
  padding: 12px 20px;
  text-decoration: none;
  border-radius: 4px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
}

.btn:hover {
  background-color: #2980b9;
}

.btn:disabled {
  background-color: #95a5a6;
  cursor: not-allowed;
}

.error-message {
  color: #e74c3c;
  background-color: #fadbd8;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
}

.success-message {
  color: #27ae60;
  background-color: #d4edda;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
  text-align: center;
  font-weight: bold;
}

.help-text {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
}
</style> 