<template>
  <header class="bg-gradient-to-r from-gray-50 to-white border-b border-gray-100/50 shadow-sm">
    <div class="container py-3">
      <div class="flex items-center justify-between">
        <div class="flex items-center">
          <img src="/img/cf_circle.png" alt="Carolina's Finest Camp" class="h-[80px] drop-shadow-sm transition-all duration-200 hover:-translate-y-0.5 hover:drop-shadow-md mr-3" />
          <h1 class="text-2xl font-bold text-gray-700 mb-0 tracking-tighter">Carolina's Finest Camp</h1>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'PageHeader'
}
</script>

