<template>
  <footer class="bg-gray-50 border-t border-gray-100 text-[0.95rem]">
    <div class="container py-4">
      <div class="row g-4 justify-content-between">
        <div class="col-md-5">
          <div class="flex items-center mb-3 md:justify-start justify-center">
            <img src="/img/cf_circle.png" alt="Carolina's Finest Camp" class="h-[80px] w-auto mr-2" />
            <h5 class="mb-0 text-secondary">Carolina's Finest Camp</h5>
          </div>

        </div>
        
       
        
        <div class="col-md-3">
          <h6 class="text-base font-semibold text-gray-700 mb-3 relative pb-2 md:text-left text-center md:after:left-0 after:left-1/2 md:after:translate-x-0 after:-translate-x-1/2 after:content-[''] after:absolute after:bottom-0 after:w-10 after:h-0.5 after:bg-gray-200 after:rounded-sm">Contact</h6>
          <ul class="list-none p-0 m-0 text-gray-600 md:text-left text-center">
            <li class="flex items-center mb-2 md:justify-start justify-center">
              <i class="bi bi-envelope-fill text-secondary me-2"></i>
              <span>carolinasfinest@gmail.com</span>
            </li>
       
          </ul>
        </div>
      </div>
    </div>
    <div class="bg-gray-100 border-t border-gray-100 text-[0.85rem]">
      <div class="container py-3">
        <div class="flex flex-col md:flex-row justify-between items-center">
          <p class="mb-0 md:mb-0 text-secondary">© 2025 Carolina's Finest Camp. All rights reserved.</p>
          <div class="flex gap-3 mt-4 md:mt-0 justify-center">
            <a href="#" class="flex items-center justify-center w-8 h-8 rounded-full bg-gray-200 text-gray-700 no-underline transition-all duration-200 hover:bg-gray-700 hover:text-white hover:-translate-y-0.5" aria-label="Facebook">
              <i class="bi bi-facebook"></i>
            </a>
            <a href="#" class="flex items-center justify-center w-8 h-8 rounded-full bg-gray-200 text-gray-700 no-underline transition-all duration-200 hover:bg-gray-700 hover:text-white hover:-translate-y-0.5" aria-label="Twitter">
              <i class="bi bi-twitter-x"></i>
            </a>
            <a href="#" class="flex items-center justify-center w-8 h-8 rounded-full bg-gray-200 text-gray-700 no-underline transition-all duration-200 hover:bg-gray-700 hover:text-white hover:-translate-y-0.5" aria-label="Instagram">
              <i class="bi bi-instagram"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'PageFooter'
}
</script> 