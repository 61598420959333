import axios from 'axios';

// Helper to get the API URL based on current hostname
const getApiUrl = () => {
  const hostname = window.location.hostname;
  
  if (process.env.NODE_ENV === 'production') {
    // In production, API calls are on the same origin
    return '';
  } else if (hostname === '10.0.1.100' || hostname === 'localhost') {
    // When running on specific hosts
    return 'http://10.0.1.100:5000';
  } else {
    // Default backend URL
    return 'http://10.0.1.100:5000';
  }
};

// Storage keys
const TOKEN_KEY = 'auth_token';
const CONFIRMATION_KEY = 'confirmation_number';
const USER_DATA_KEY = 'user_data';

/**
 * Store authentication data in localStorage
 */
const storeAuthData = (token, userData, confirmationNumber) => {
  localStorage.setItem(TOKEN_KEY, token);
  localStorage.setItem(CONFIRMATION_KEY, confirmationNumber);
  localStorage.setItem(USER_DATA_KEY, JSON.stringify(userData));
};

/**
 * Get stored auth token
 */
const getAuthToken = () => {
  return localStorage.getItem(TOKEN_KEY);
};

/**
 * Get stored confirmation number
 */
const getConfirmationNumber = () => {
  return localStorage.getItem(CONFIRMATION_KEY);
};

/**
 * Get stored user data
 */
const getUserData = () => {
  const userData = localStorage.getItem(USER_DATA_KEY);
  return userData ? JSON.parse(userData) : null;
};

/**
 * Clear all auth data from localStorage
 */
const clearAuthData = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(CONFIRMATION_KEY);
  localStorage.removeItem(USER_DATA_KEY);
};

/**
 * Check if user is authenticated
 */
const isAuthenticated = () => {
  return !!getAuthToken() && !!getConfirmationNumber();
};

/**
 * Handle login and token generation
 */
const login = async (email, confirmationNumber, password = null) => {
  try {
    console.log(`Attempting login with: email=${email}, confirmation=${confirmationNumber || 'none'}, password=${password ? 'provided' : 'none'}`);
    const apiUrl = getApiUrl();
    
    // Step 1: First check if registration exists
    console.log(`Step 1: Calling ${apiUrl}/api/check-registration`);
    const checkResponse = await axios.post(`${apiUrl}/api/check-registration`, {
      email,
      confirmationNumber: confirmationNumber || null,
      password: password || null
    });
    
    console.log('check-registration response:', checkResponse.data);
    
    if (checkResponse.data.status !== 'success') {
      console.error('Registration check failed:', checkResponse.data.message);
      throw new Error(checkResponse.data.message || 'Login failed. Please check your credentials.');
    }
    
    // Step 2: If registration check is successful, get a proper auth token
    const userData = checkResponse.data.data;
    
    if (!userData || !userData.confirmation_number) {
      console.error('Registration data is missing confirmation number', userData);
      throw new Error('Registration data is incomplete. Please contact support.');
    }
    
    console.log(`Step 2: Requesting token from ${apiUrl}/token with confirmation ${userData.confirmation_number}`);
    
    // Request a token from the token endpoint
    const tokenResponse = await axios.post(`${apiUrl}/token`, {
      email: userData.email,
      confirmation_number: userData.confirmation_number
    });
    
    console.log('Token endpoint response:', tokenResponse.data);
    
    if (!tokenResponse.data.access_token) {
      console.error('Token response missing token');
      throw new Error('Authentication server error. Please try again later.');
    }
    
    const token = tokenResponse.data.access_token;
    const expires = tokenResponse.data.expires;
    
    console.log(`Received token (expiry: ${expires}), storing auth data...`);
    
    // Store the token and user data
    storeAuthData(
      token,
      userData, 
      userData.confirmation_number
    );
    
    console.log('Auth data stored successfully!');
    return userData;
  } catch (error) {
    console.error('Login error:', error);
    
    // Check if it's an axios error with a response
    if (error.response) {
      console.error('Status:', error.response.status);
      console.error('Data:', error.response.data);
      
      // If we have an error message from the server, use it
      if (error.response.data && error.response.data.detail) {
        throw new Error(error.response.data.detail);
      } else if (error.response.data && error.response.data.message) {
        throw new Error(error.response.data.message);
      }
    }
    
    // Rethrow the original error if we can't extract a better message
    throw error;
  }
};

/**
 * Handle logout
 */
const logout = () => {
  clearAuthData();
};

export {
  login,
  logout,
  isAuthenticated,
  getAuthToken,
  getConfirmationNumber,
  getUserData
}; 