<template>
  <div class="success-container">
    <div class="header">
      <h1>Carolina's Finest Referee Camp</h1>
      <div class="success-badge">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="checkmark">
          <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
          <polyline points="22 4 12 14.01 9 11.01"></polyline>
        </svg>
        <span>Registration Complete</span>
      </div>
    </div>
    
    <div v-if="loading" class="loading-container">
      <div class="spinner"></div>
      <p>Loading your registration details...</p>
    </div>
    
    <div v-else-if="error" class="error-container">
      <p>{{ error }}</p>
      <p>Please contact support if you continue to experience issues.</p>
    </div>
    
    <div v-else class="content">
      <div v-if="isFallbackMode" class="fallback-notification">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
          <circle cx="12" cy="12" r="10"></circle>
          <line x1="12" y1="8" x2="12" y2="12"></line>
          <line x1="12" y1="16" x2="12.01" y2="16"></line>
        </svg>
        <span>{{ fallbackMessage }}</span>
      </div>
      
      <div class="confirmation-box">
        <h2>Your registration has been confirmed!</h2>
        <p v-if="isSpecialRegistration">
          Thank you for registering for Carolina's Finest Referee Camp. Your registration has been completed with special access, and no payment is required. A confirmation email has been sent to your email address.
        </p>
        <p v-else>
          Thank you for registering for Carolina's Finest Referee Camp. A confirmation email has been sent to your email address.
        </p>
        
        <div class="detail-row">
          <span class="label">Confirmation Number</span>
          <span class="value">{{ confirmationNumber }}</span>
        </div>
      
        <div class="detail-row registration-date" v-if="registrationDate">
          <span class="label">Registration Date</span>
          <span class="value">{{ formatDate(registrationDate) }}</span>
        </div>
        
        <div v-if="isSpecialRegistration" class="special-registration-notice">
          <p>You've been registered with special access. No payment is required for this registration.</p>
        </div>
      </div>

      <div class="actions">
        <button @click="downloadReceipt" class="btn download-btn" :disabled="loadingReceipt || isFallbackMode">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon">
            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
            <polyline points="7 10 12 15 17 10"></polyline>
            <line x1="12" y1="15" x2="12" y2="3"></line>
          </svg>
          {{ loadingReceipt ? 'Generating...' : 'Download Receipt' }}
        </button>
        
        <router-link to="/" class="btn outline">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon">
            <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
            <polyline points="9 22 9 12 15 12 15 22"></polyline>
          </svg>
          Return to Homepage
        </router-link>
      </div>

      <div v-if="errorMessage" class="error-message">
        <p>{{ errorMessage }}</p>
      </div>
    </div>
    
    <div class="support">
      Need help? <a href="mailto:camp@carolinasfinestrefereecamp.com">Contact our support team</a>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import html2pdf from 'html2pdf.js';

// Helper to get the API URL based on current hostname
function getApiUrl() {
  const hostname = window.location.hostname;
  return `http://${hostname}:5000`;
}

export default {
  name: 'SuccessView',
  data() {
    return {
      confirmationNumber: '',
      tempPassword: null,
      loading: true,
      error: null,
      sessionId: null,
      campDate: '',
      registrationDate: null,
      loadingReceipt: false,
      isFallbackMode: false,
      errorMessage: null,
      isSpecialRegistration: false,
      fallbackMessage: 'We are experiencing some technical difficulties, but your registration has been confirmed. Your confirmation number has been generated and is shown below.'
    };
  },
  created() {
    // Check if this is a special registration
    this.isSpecialRegistration = this.$route.query.special === 'true';
    
    // Get the confirmation number directly if it's a special registration
    if (this.isSpecialRegistration && this.$route.query.confirmation) {
      this.confirmationNumber = this.$route.query.confirmation;
      this.loading = false;
    } 
    // Otherwise process the session_id for regular registrations
    else {
      // Get the session_id from URL query parameters
      this.sessionId = this.$route.query.session_id;
      
      if (this.sessionId) {
        this.getConfirmationDetails();
      } else if (this.$route.query.confirmation) {
        // Fallback if just the confirmation is provided directly
        this.confirmationNumber = this.$route.query.confirmation;
        this.loading = false;
      } else {
        this.loading = false;
        this.error = 'No session ID provided. Unable to retrieve registration details.';
      }
    }
  },
  methods: {
    async getConfirmationDetails() {
      const apiUrl = getApiUrl();
      try {
        // First check if there's a direct API endpoint
        const response = await axios.get(`${apiUrl}/api/success-details?session_id=${this.sessionId}`);
        if (response.data && response.data.confirmation_number) {
          this.confirmationNumber = response.data.confirmation_number;
          this.tempPassword = response.data.temp_password;
          this.campDate = response.data.camp_date || '';
          this.registrationDate = response.data.registration_date || null;
          
          // Check if this is fallback mode
          if (response.data.is_fallback) {
            this.isFallbackMode = true;
            console.log('Operating in database fallback mode');
            
            // If there's a specific error message, use it
            if (response.data.error_message) {
              this.errorMessage = response.data.error_message;
              this.fallbackMessage = 'We encountered an issue with our system, but your payment has been processed.';
            }
          }
          
          this.loading = false;
        } else {
          throw new Error('Invalid response from server');
        }
      } catch (error) {
        console.error('Error fetching confirmation details:', error);
        this.error = 'Unable to retrieve your registration details. Please contact support.';
        this.loading = false;
      }
    },
    formatDate(dateString) {
      if (!dateString) return '';
      
      // If it's already a string in a good format, return it
      if (typeof dateString === 'string' && !dateString.includes('T')) {
        return dateString;
      }
      
      try {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        });
      } catch (e) {
        return dateString; // Return original if parsing fails
      }
    },
    
    // Helper function to safely show toast messages
    showToast(type, message) {
      console.log(`Toast ${type}: ${message}`);
      if (this.$toast && typeof this.$toast[type] === 'function') {
        this.$toast[type](message);
      } else {
        // Fallback for when toast is not available
        console.log(`Toast not available: ${type} - ${message}`);
        alert(message);
      }
    },
    
    async downloadReceipt() {
      // Show loading state
      this.loadingReceipt = true;
      
      try {
        // Check if we have a confirmation number
        if (!this.confirmationNumber) {
          console.error('No confirmation number available');
          this.showToast('error', 'Unable to download receipt - confirmation number is missing');
          return;
        }
        
        console.log(`Downloading receipt for confirmation: ${this.confirmationNumber}`);
        
        // Get the API URL
        const apiUrl = getApiUrl();
        
        // Create a direct link to the receipt endpoint with special parameter if needed
        const specialParam = this.isSpecialRegistration ? '?special=true' : '';
        const receiptUrl = `${apiUrl}/api/receipt/${this.confirmationNumber}${specialParam}`;
        
        // Open the receipt URL in a new tab/window
        window.open(receiptUrl, '_blank');
        
        console.log('Receipt download initiated');
      } catch (error) {
        // Catch any unexpected errors
        console.error('Unexpected error in downloadReceipt:', error);
        this.showToast('error', 'An unexpected error occurred. Please try again later.');
      } finally {
        // Always reset loading state
        this.loadingReceipt = false;
      }
    }
  }
};
</script>

<style scoped>
.success-container {
  max-width: 700px;
  margin: 40px auto;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  overflow: hidden;
}

.header {
  background: #4a6da7;
  color: white;
  padding: 28px 30px;
  text-align: center;
}

.header h1 {
  margin: 0 0 15px;
  font-size: 24px;
  font-weight: 600;
  color: white;
}

.success-badge {
  display: inline-flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.15);
  padding: 8px 16px;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 500;
  color: white;
}

.checkmark {
  color: white;
  margin-right: 8px;
}

.content {
  padding: 30px;
}

.confirmation-box {
  margin-bottom: 25px;
}

.confirmation-box h2 {
  font-size: 20px;
  margin: 0 0 10px;
  color: #334155;
  font-weight: 600;
  line-height: 1.4;
}

.confirmation-box p {
  color: #64748b;
  margin: 0 0 20px;
  font-size: 15px;
}

.detail-row {
  display: flex;
  flex-direction: column;
  background: #f8fafc;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 12px;
  border: 1px solid #e2e8f0;
}

.detail-row:last-child {
  margin-bottom: 0;
}

.detail-row.camp-details {
  border-left: 3px solid #10b981;
}

.detail-row.registration-date {
  border-left: 3px solid #0ea5e9;
}

.label {
  font-size: 12px;
  color: #64748b;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-bottom: 6px;
  font-weight: 500;
}

.value {
  font-size: 18px;
  font-weight: 600;
  color: #334155;
  letter-spacing: 0.3px;
}

.account-box {
  background: #f8fafc;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 25px;
  border: 1px solid #e2e8f0;
}

.account-box h3 {
  margin: 0 0 12px;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #334155;
  font-weight: 600;
}

.account-box h3 svg {
  margin-right: 8px;
  color: #8b5cf6;
}

.account-box p {
  color: #64748b;
  margin: 0 0 15px;
  font-size: 14px;
  line-height: 1.5;
}

.password-container {
  background: white;
  border-radius: 6px;
  padding: 14px;
  border: 1px solid #e2e8f0;
}

code.password {
  display: block;
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, monospace;
  background: #f1f5f9;
  padding: 12px;
  border-radius: 4px;
  color: #334155;
  font-size: 15px;
  margin: 10px 0;
  border: 1px solid #e2e8f0;
  letter-spacing: 0.5px;
  text-align: center;
}

.password-note {
  color: #dc2626;
  font-size: 13px;
  margin-top: 8px;
}

.password-note:before {
  content: "⚠️";
  margin-right: 6px;
}

.special-registration-notice {
  background-color: #d4edda;
  color: #155724;
  padding: 15px;
  border-radius: 8px;
  margin-top: 15px;
  border-left: 4px solid #28a745;
}

.special-registration-notice p {
  color: #155724;
  margin: 0;
  font-weight: 500;
}

.actions {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 30px;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px 16px;
  font-size: 15px;
  font-weight: 500;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  text-decoration: none;
  border: none;
  font-family: inherit;
}

.btn .icon {
  margin-right: 8px;
}

.download-btn {
  background: #4a6da7;
  color: white;
}

.download-btn:hover:not(:disabled) {
  background: #3b5998;
}

.outline {
  border: 1px solid #cbd5e0;
  color: #475569;
  background: white;
}

.outline:hover {
  background: #f8fafc;
}

.account-reminder {
  margin-top: 20px;
  background-color: #f0f9ff;
  border-radius: 8px;
  border: 1px solid #e0f2fe;
  padding: 14px;
}

.account-reminder p {
  display: flex;
  align-items: flex-start;
  margin: 0;
  color: #475569;
  font-size: 14px;
  line-height: 1.5;
}

.account-reminder svg {
  margin-right: 8px;
  margin-top: 2px;
  color: #0ea5e9;
  flex-shrink: 0;
}

.account-reminder a {
  color: #0ea5e9;
  font-weight: 500;
  text-decoration: none;
  margin-left: 4px;
}

.account-reminder a:hover {
  text-decoration: underline;
}

.support {
  text-align: center;
  padding: 20px;
  background: #f8fafc;
  border-top: 1px solid #e2e8f0;
  color: #64748b;
  font-size: 14px;
}

.support a {
  color: #4a6da7;
  text-decoration: none;
  font-weight: 500;
}

.support a:hover {
  text-decoration: underline;
}

.loading-container, .error-container {
  padding: 40px 30px;
  text-align: center;
}

.spinner {
  width: 30px;
  height: 30px;
  border: 2px solid #e2e8f0;
  border-top: 2px solid #4a6da7;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin: 0 auto 20px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.error-container {
  color: #dc2626;
}

.fallback-notification {
  background-color: #fffbeb;
  border: 1px solid #fef3c7;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 20px;
  display: flex;
  align-items: flex-start;
}

.fallback-notification svg {
  color: #d97706;
  margin-right: 10px;
  flex-shrink: 0;
  margin-top: 2px;
}

.fallback-notification span {
  color: #92400e;
  font-size: 14px;
  line-height: 1.5;
}

.error-message {
  background-color: #fef2f2;
  border: 1px solid #fee2e2;
  padding: 14px;
  border-radius: 8px;
  margin-top: 20px;
  color: #b91c1c;
}

.btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

@media (min-width: 640px) {
  .actions {
    flex-direction: row;
    flex-wrap: wrap;
  }
  
  .btn {
    flex: 1;
    min-width: 120px;
  }
  
  .detail-row {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  
  .detail-row .label {
    margin-bottom: 0;
  }
}
</style> 