<template>
  <div class="min-h-screen bg-gray-50">
    <PageHeader />
    
    <!-- Main Content -->
    <div class="container mx-auto px-6 py-16">
      <div class="mb-24">
        <div class="max-w-5xl mx-auto bg-white border border-gray-200 rounded shadow-sm overflow-hidden">
          <div class="flex justify-between items-center p-6 border-b border-gray-200 bg-gray-50">
            <h2 class="text-xl font-medium text-gray-900 mb-0 flex items-center">
              <i class="bi bi-pencil-square me-3 text-gray-500"></i>
              Scholarship Registration Form
            </h2>
          </div>
          <div class="p-6">
            <RegistrationForm :available-camps="camps" :selected-camp="defaultCamp" :is-special-registration="true" />
          </div>
        </div>
      </div>
    </div>
    
    <PageFooter />
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader.vue'
import RegistrationForm from '@/components/RegistrationForm.vue'
import PageFooter from '@/components/PageFooter.vue'

export default {
  name: 'SpecialRegistrationView',
  components: {
    PageHeader,
    RegistrationForm,
    PageFooter
  },
  data() {
    return {
      camps: [
        {
          id: 'special_camp_1',
          name: 'Carolina\'s Finest Referee Camp',
          dates: 'May 2nd - May 4th, 2025',
          times: '8:00 AM - 6:00 PM',
          cost: '0',
          isFull: false
        },
        {
          id: 'special_camp_2',
          name: 'Carolina\'s Finest Referee Camp - Session 2',
          dates: 'May 9th - May 11th, 2025',
          times: '8:00 AM - 6:00 PM',
          cost: '0',
          isFull: false
        }
      ],
      defaultCamp: {
        id: 'special_camp_1',
        name: 'Carolina\'s Finest Referee Camp',
        dates: 'May 2nd - May 4th, 2025',
        times: '8:00 AM - 6:00 PM',
        cost: '0'
      }
    }
  }
}
</script> 