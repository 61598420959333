<template>
  <div class="verify-code-container">
    <transition name="slide-fade" mode="out-in">
      <!-- Verification form -->
      <div v-if="!showRegistrations" key="verification-form" class="h-screen flex items-center justify-center">
        <div class="max-w-md w-full mx-auto bg-white rounded-md border border-gray-200 overflow-hidden">
          <div class="bg-slate-600 p-6 text-white">
            <div class="flex flex-col items-center">
              <img src="/img/cf_circle.png" alt="Carolina's Finest Logo" class="w-16 h-16 mb-4 rounded-full">
              <h1 class="text-3xl font-bold text-center">Admin Verification</h1>
            </div>
          </div>
          
          <div class="p-8">
            <p class="text-center text-gray-600 mb-8">Please enter the 6-digit verification code to access registrations</p>
            
            <div class="code-inputs flex justify-center space-x-3 mb-8">
              <input 
                v-for="(digit, index) in codeDigits" 
                :key="index"
                type="text" 
                maxlength="1"
                v-model="codeDigits[index]"
                @input="onDigitInput(index)"
                @keydown="onKeyDown($event, index)"
                ref="digitInputs"
                class="w-12 h-14 border-b-2 border-gray-300 rounded-md text-center text-2xl font-bold focus:border-slate-500 focus:outline-none transition-all bg-gray-50"
              />
            </div>
            
            <div v-if="errorMessage" class="bg-red-50 text-red-500 p-3 rounded-md text-center mb-6">
              {{ errorMessage }}
            </div>
            
            <div class="text-center">
              <button 
                @click="verifyCode" 
                class="bg-slate-600 hover:bg-slate-700 text-white font-bold py-3 px-8 rounded-md transition-colors w-full"
                :disabled="loading"
              >
                <span v-if="loading" class="flex items-center justify-center">
                  <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Verifying...
                </span>
                <span v-else>Submit</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Registrations list -->
      <div v-else key="registrations-list" class="h-screen flex flex-col bg-gray-50">
        <!-- Header -->
        <div class="bg-slate-700 py-4 px-6 text-white sticky top-0 z-20 shadow-md">
          <div class="flex justify-between items-center">
            <div class="flex items-center">
              <img src="/img/cf_circle.png" alt="Carolina's Finest Logo" class="w-10 h-10 mr-4">
              <h2 class="text-2xl font-semibold">Registrations ({{ displayedRegistrations.length }})</h2>
            </div>
            <div class="flex items-center space-x-3">
              <button 
                @click="downloadCSV" 
                class="flex items-center px-4 py-2 bg-white text-slate-700 rounded-md hover:bg-gray-100 transition-colors shadow-sm"
                :disabled="downloadingCSV"
              >
                <span v-if="downloadingCSV">
                  <svg class="animate-spin -ml-1 mr-2 h-5 w-5 text-slate-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Downloading...
                </span>
                <span v-else>
                  <svg class="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path>
                  </svg>
                  Download CSV
                </span>
              </button>
              <button 
                @click="showRegistrations = false" 
                class="bg-white text-slate-700 p-2 rounded-md hover:bg-gray-100 transition-colors shadow-sm"
                title="Back to verification"
              >
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                </svg>
              </button>
            </div>
          </div>
        </div>
        
        <!-- Filters section -->
        <div class="bg-white border-b border-gray-200 py-3 px-6 sticky top-[72px] z-10 shadow-sm">
          <!-- Basic filters -->
          <div class="flex flex-wrap items-center gap-4 mb-4">
            <div class="flex items-center">
              <span class="text-gray-600 mr-2 whitespace-nowrap text-sm font-medium">Status:</span>
              <div class="flex space-x-2">
                <button 
                  @click="filters.status = 'all'" 
                  :class="{
                    'px-3 py-1.5 text-sm font-medium rounded-md transition-colors shadow-sm': true,
                    'bg-slate-600 text-white': filters.status === 'all',
                    'bg-gray-100 text-gray-600 hover:bg-gray-200': filters.status !== 'all'
                  }"
                >
                  All
                </button>
                <button 
                  @click="filters.status = 'completed'" 
                  :class="{
                    'px-3 py-1.5 text-sm font-medium rounded-md transition-colors shadow-sm': true,
                    'bg-emerald-600 text-white': filters.status === 'completed',
                    'bg-gray-100 text-gray-600 hover:bg-gray-200': filters.status !== 'completed'
                  }"
                >
                  Completed
                </button>
                <button 
                  @click="filters.status = 'pending'" 
                  :class="{
                    'px-3 py-1.5 text-sm font-medium rounded-md transition-colors shadow-sm': true,
                    'bg-amber-500 text-white': filters.status === 'pending',
                    'bg-gray-100 text-gray-600 hover:bg-gray-200': filters.status !== 'pending'
                  }"
                >
                  Pending
                </button>
              </div>
            </div>
            
            <div class="flex-1">
              <div class="relative">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                  </svg>
                </div>
                <input 
                  type="text" 
                  v-model="filters.search" 
                  placeholder="Search name or email..." 
                  class="w-full pl-10 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-slate-500 focus:border-transparent"
                />
                <button 
                  v-if="filters.search" 
                  @click="filters.search = ''" 
                  class="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600"
                >
                  <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
                  </svg>
                </button>
              </div>
            </div>
            
            <div class="flex items-center">
              <button 
                @click="showAdvancedFilters = !showAdvancedFilters" 
                class="flex items-center text-slate-600 hover:text-slate-800 text-sm font-medium"
              >
                <span>{{ showAdvancedFilters ? 'Hide' : 'Show' }} Advanced Filters</span>
                <svg 
                  class="w-5 h-5 ml-1 transition-transform" 
                  :class="{ 'transform rotate-180': showAdvancedFilters }"
                  fill="none" 
                  stroke="currentColor" 
                  viewBox="0 0 24 24" 
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
                </svg>
              </button>
              
              <div class="ml-4 relative">
                <button 
                  @click="showDistanceLegend = !showDistanceLegend" 
                  class="flex items-center text-slate-600 hover:text-slate-800 text-sm font-medium"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  Distance Legend
                </button>
                
                <div 
                  v-if="showDistanceLegend" 
                  class="absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-20"
                  v-click-outside="() => { showDistanceLegend = false }"
                >
                  <div class="p-3">
                    <h3 class="text-xs font-medium text-gray-500 uppercase mb-2">Distance Color Coding</h3>
                    <div class="space-y-2">
                      <div class="flex items-center">
                        <span class="w-3 h-3 rounded-full bg-emerald-500 mr-2"></span>
                        <span class="text-sm text-gray-700">0-5 miles (Very Close)</span>
                      </div>
                      <div class="flex items-center">
                        <span class="w-3 h-3 rounded-full bg-blue-500 mr-2"></span>
                        <span class="text-sm text-gray-700">5-15 miles (Moderate)</span>
                      </div>
                      <div class="flex items-center">
                        <span class="w-3 h-3 rounded-full bg-amber-500 mr-2"></span>
                        <span class="text-sm text-gray-700">15-30 miles (Farther)</span>
                      </div>
                      <div class="flex items-center">
                        <span class="w-3 h-3 rounded-full bg-gray-500 mr-2"></span>
                        <span class="text-sm text-gray-700">30+ miles (Remote)</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <!-- Advanced filters -->
          <div v-if="showAdvancedFilters" class="grid grid-cols-1 md:grid-cols-3 gap-4 p-4 mt-2 bg-gray-50 rounded-lg">
            <div>
              <label class="block text-sm font-medium text-gray-700 mb-1">State</label>
              <select 
                v-model="filters.state" 
                class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-slate-500 focus:border-transparent"
              >
                <option value="">All States</option>
                <option v-for="state in uniqueStates" :key="state" :value="state">{{ state }}</option>
              </select>
            </div>
            
            <div>
              <label class="block text-sm font-medium text-gray-700 mb-1">Experience Level</label>
              <select 
                v-model="filters.experienceLevel" 
                class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-slate-500 focus:border-transparent"
              >
                <option value="">All Experience Levels</option>
                <option value="beginner">Beginner (0-3 years)</option>
                <option value="intermediate">Intermediate (4-7 years)</option>
                <option value="experienced">Experienced (8+ years)</option>
              </select>
            </div>
            
            <div>
              <label class="block text-sm font-medium text-gray-700 mb-1">Highest Level</label>
              <select 
                v-model="filters.highestLevel" 
                class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-slate-500 focus:border-transparent"
              >
                <option value="">All Levels</option>
                <option v-for="level in uniqueLevels" :key="level" :value="level">{{ level }}</option>
              </select>
            </div>
            
            <div class="md:col-span-3 flex justify-end">
              <button 
                @click="resetFilters" 
                class="bg-gray-200 hover:bg-gray-300 text-gray-700 px-4 py-2 rounded-md text-sm font-medium shadow-sm"
              >
                Reset Filters
              </button>
            </div>
          </div>
        </div>
        
        <div class="flex-1 overflow-auto p-6">
          <div v-if="displayedRegistrations.length === 0" class="bg-white p-8 text-center text-gray-500 border border-gray-200 rounded-lg shadow-sm">
            No registrations match your filter criteria
          </div>
          
          <div v-else class="overflow-x-auto bg-white border border-gray-200 rounded-lg shadow-sm">
            <table class="w-full bg-white">
              <thead>
                <tr class="bg-slate-50 border-b border-slate-200">
                  <th class="py-3 px-4 text-left text-xs font-medium text-slate-600 uppercase tracking-wider">Name</th>
                  <th class="py-3 px-4 text-left text-xs font-medium text-slate-600 uppercase tracking-wider">Email</th>
                  <th class="py-3 px-4 text-left text-xs font-medium text-slate-600 uppercase tracking-wider">Location</th>
                  <th class="py-3 px-4 text-left text-xs font-medium text-slate-600 uppercase tracking-wider">Experience (Years)</th>
                  <th class="py-3 px-4 text-left text-xs font-medium text-slate-600 uppercase tracking-wider">Highest Level</th>
                  <th class="py-3 px-4 text-left text-xs font-medium text-slate-600 uppercase tracking-wider">Tournament Exp</th>
                  <th class="py-3 px-4 text-left text-xs font-medium text-slate-600 uppercase tracking-wider">Conferences</th>
                  <th class="py-3 px-4 text-left text-xs font-medium text-slate-600 uppercase tracking-wider">Nearby Schools</th>
                  <th class="py-3 px-4 text-left text-xs font-medium text-slate-600 uppercase tracking-wider">Status</th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200">
                <tr v-for="reg in displayedRegistrations" :key="reg._id" class="hover:bg-slate-50 transition-colors">
                  <td class="py-3.5 px-4 text-sm font-medium text-gray-900">{{ reg.name }}</td>
                  <td class="py-3.5 px-4 text-sm text-gray-600">{{ reg.email }}</td>
                  <td class="py-3.5 px-4 text-sm text-gray-600">{{ reg.city }}, {{ reg.state }} {{ reg.zip }}</td>
                  <td class="py-3.5 px-4 text-sm text-gray-600">{{ reg.yearsOfExperience || 'N/A' }}</td>
                  <td class="py-3.5 px-4 text-sm text-gray-600">{{ reg.highestWorked || 'N/A' }}</td>
                  <td class="py-3.5 px-4 text-sm text-gray-600">{{ reg.tournamentExperience || 'N/A' }}</td>
                  <td class="py-3.5 px-4 text-sm text-gray-600">{{ reg.conferences || 'N/A' }}</td>
                  <td class="py-3.5 px-4">
                    <div v-if="reg.nearby_schools && reg.nearby_schools.length > 0" class="max-h-40 overflow-y-auto pr-2">
                      <div 
                        v-for="(school, index) in reg.nearby_schools" 
                        :key="index" 
                        class="mb-1.5 px-2 py-1 rounded text-xs font-medium flex items-center"
                        :class="getSchoolColorClass(school.distance)"
                      >
                        <span class="mr-1.5 flex-shrink-0 w-2 h-2 rounded-full" :class="getDistanceDotColor(school.distance)"></span>
                        {{ school.school_name }} 
                        <span class="ml-1 text-xs opacity-80">({{ school.distance.toFixed(1) }} mi)</span>
                      </div>
                    </div>
                    <span v-else class="text-gray-500 text-sm py-2 block">None found</span>
                  </td>
                  <td class="py-3.5 px-4 whitespace-nowrap">
                    <span 
                      :class="{
                        'px-2.5 py-1 rounded-full text-xs font-medium inline-flex items-center': true,
                        'bg-emerald-100 text-emerald-800': reg.payment_status === 'completed',
                        'bg-amber-100 text-amber-800': reg.payment_status === 'pending'
                      }"
                    >
                      <span 
                        class="w-1.5 h-1.5 rounded-full mr-1.5"
                        :class="{
                          'bg-emerald-600': reg.payment_status === 'completed',
                          'bg-amber-600': reg.payment_status === 'pending'
                        }"
                      ></span>
                      {{ reg.payment_status || 'Unknown' }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'VerifyCodeView',
  directives: {
    'click-outside': {
      mounted(el, binding) {
        el.clickOutsideEvent = (event) => {
          // Check if the click was outside the element
          if (!(el === event.target || el.contains(event.target))) {
            binding.value(event)
          }
        }
        document.addEventListener('click', el.clickOutsideEvent)
      },
      unmounted(el) {
        document.removeEventListener('click', el.clickOutsideEvent)
      },
    },
  },
  data() {
    return {
      codeDigits: ['', '', '', '', '', ''],
      errorMessage: '',
      loading: false,
      showRegistrations: false,
      registrations: [],
      downloadingCSV: false,
      showAdvancedFilters: false,
      showDistanceLegend: false,
      filters: {
        search: '',
        status: 'all',
        state: '',
        experienceLevel: '',
        highestLevel: ''
      }
    }
  },
  computed: {
    filteredRegistrations() {
      // Filter to only show completed or pending registrations
      return this.registrations.filter(reg => 
        reg.payment_status === 'completed' || reg.payment_status === 'pending'
      );
    },
    displayedRegistrations() {
      // Start with the base filtered registrations
      let result = this.filteredRegistrations;
      
      // Apply status filter
      if (this.filters.status !== 'all') {
        result = result.filter(reg => reg.payment_status === this.filters.status);
      }
      
      // Apply search filter
      if (this.filters.search.trim()) {
        const searchLower = this.filters.search.toLowerCase().trim();
        result = result.filter(reg => 
          (reg.name && reg.name.toLowerCase().includes(searchLower)) || 
          (reg.email && reg.email.toLowerCase().includes(searchLower))
        );
      }
      
      // Apply state filter
      if (this.filters.state) {
        result = result.filter(reg => reg.state === this.filters.state);
      }
      
      // Apply experience level filter
      if (this.filters.experienceLevel) {
        const yearsToNumber = reg => {
          const years = parseInt(reg.yearsOfExperience, 10);
          return isNaN(years) ? 0 : years;
        };
        
        switch (this.filters.experienceLevel) {
          case 'beginner':
            result = result.filter(reg => {
              const years = yearsToNumber(reg);
              return years >= 0 && years <= 3;
            });
            break;
          case 'intermediate':
            result = result.filter(reg => {
              const years = yearsToNumber(reg);
              return years >= 4 && years <= 7;
            });
            break;
          case 'experienced':
            result = result.filter(reg => {
              const years = yearsToNumber(reg);
              return years >= 8;
            });
            break;
        }
      }
      
      // Apply highest level filter
      if (this.filters.highestLevel) {
        result = result.filter(reg => reg.highestWorked === this.filters.highestLevel);
      }
      
      return result;
    },
    uniqueStates() {
      // Get unique states from registrations
      const states = new Set();
      this.filteredRegistrations.forEach(reg => {
        if (reg.state) states.add(reg.state);
      });
      return [...states].sort();
    },
    uniqueLevels() {
      // Get unique highest worked levels
      const levels = new Set();
      this.filteredRegistrations.forEach(reg => {
        if (reg.highestWorked) levels.add(reg.highestWorked);
      });
      return [...levels].sort();
    }
  },
  methods: {
    async verifyCode() {
      // Combine digits into a single code
      const code = this.codeDigits.join('')
      
      // Check if all digits are filled
      if (code.length !== 6) {
        this.errorMessage = 'Please enter all 6 digits'
        return
      }
      
      this.loading = true
      this.errorMessage = ''
      
      try {
        const response = await fetch('/api/admin/verify-code', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ code })
        })
        
        const data = await response.json()
        
        if (data.success) {
          this.registrations = data.registrations
          this.showRegistrations = true
        } else {
          this.errorMessage = data.message
        }
        
      } catch (error) {
        console.error('Error verifying code:', error)
        this.errorMessage = 'An error occurred while verifying the code.'
      } finally {
        this.loading = false
      }
    },
    
    onDigitInput(index) {
      // Move to next input after filling current one
      if (this.codeDigits[index] && index < 5) {
        this.$refs.digitInputs[index + 1].focus()
      }
      
      // Ensure only numbers are entered
      this.codeDigits[index] = this.codeDigits[index].replace(/[^0-9]/g, '')
    },
    
    onKeyDown(event, index) {
      // Handle backspace to move to previous input
      if (event.key === 'Backspace' && !this.codeDigits[index] && index > 0) {
        this.$refs.digitInputs[index - 1].focus()
      }
    },
    
    resetFilters() {
      this.filters = {
        search: '',
        status: 'all',
        state: '',
        experienceLevel: '',
        highestLevel: ''
      };
    },
    
    // Color coding for nearby schools based on distance
    getSchoolColorClass(distance) {
      // Very close (< 5 miles)
      if (distance < 5) return 'bg-emerald-50 text-emerald-800';
      // Moderate distance (5-15 miles)
      if (distance < 15) return 'bg-blue-50 text-blue-800';
      // Farther distance (15-30 miles)
      if (distance < 30) return 'bg-amber-50 text-amber-800';
      // Remote (> 30 miles)
      return 'bg-gray-50 text-gray-600';
    },
    
    // Dot indicator color for distance
    getDistanceDotColor(distance) {
      // Very close (< 5 miles)
      if (distance < 5) return 'bg-emerald-500';
      // Moderate distance (5-15 miles)
      if (distance < 15) return 'bg-blue-500';
      // Farther distance (15-30 miles)
      if (distance < 30) return 'bg-amber-500';
      // Remote (> 30 miles)
      return 'bg-gray-500';
    },
    
    downloadCSV() {
      this.downloadingCSV = true
      
      try {
        // Define the CSV headers - updated to include new fields
        const headers = [
          'Name', 
          'Email', 
          'Cell Phone', 
          'Address', 
          'City', 
          'State', 
          'Zip', 
          'Years of Experience',
          'Highest Worked',
          'Tournament Experience',
          'Conferences',
          'Nearby Schools',
          'Payment Status', 
          'Registration Date'
        ]
        
        // Use currently displayed registrations for export (respect filters)
        const rows = this.displayedRegistrations.map(reg => {
          // Format registration date if available
          const regDate = reg.registrationDate 
            ? new Date(reg.registrationDate).toLocaleDateString() 
            : 'N/A'
            
          // Format nearby schools as a comma-separated list
          const nearbySchools = reg.nearby_schools && reg.nearby_schools.length > 0
            ? reg.nearby_schools.map(school => `${school.school_name} (${school.distance.toFixed(1)} mi)`).join('; ')
            : 'None'
            
          return [
            reg.name || '',
            reg.email || '',
            reg.cellPhone || '',
            reg.address || '',
            reg.city || '',
            reg.state || '',
            reg.zip || '',
            reg.yearsOfExperience || '',
            reg.highestWorked || '',
            reg.tournamentExperience || '',
            reg.conferences || '',
            nearbySchools,
            reg.payment_status || 'Unknown',
            regDate
          ].map(value => `"${String(value).replace(/"/g, '""')}"`).join(',')
        })
        
        // Combine headers and rows
        const csv = [
          headers.join(','),
          ...rows
        ].join('\n')
        
        // Create a Blob from the CSV data
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
        
        // Create a download link and trigger the download
        const url = URL.createObjectURL(blob)
        const link = document.createElement('a')
        const timestamp = new Date().toISOString().split('T')[0]
        
        link.setAttribute('href', url)
        link.setAttribute('download', `carolina-finest-registrations-${timestamp}.csv`)
        link.style.visibility = 'hidden'
        
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        
      } catch (error) {
        console.error('Error generating CSV:', error)
        alert('There was an error generating the CSV file.')
      } finally {
        // Set a small timeout to make the spinner visible for at least a moment
        setTimeout(() => {
          this.downloadingCSV = false
        }, 500)
      }
    }
  },
  mounted() {
    // Focus first input when component mounts
    this.$nextTick(() => {
      this.$refs.digitInputs[0].focus()
    })
  }
}
</script>

<style scoped>
.verify-code-container {
  background-color: #f5f5f5;
  min-height: 100vh;
  width: 100%;
}

/* Slide fade transition */
.slide-fade-enter-active {
  transition: all 0.5s ease;
}

.slide-fade-leave-active {
  transition: all 0.3s ease;
}

.slide-fade-enter-from {
  transform: translateY(30px);
  opacity: 0;
}

.slide-fade-leave-to {
  transform: translateY(-30px);
  opacity: 0;
}
</style> 