<template>
  <div class="status-page">
    <!-- Left Panel - Navigation only -->
    <div class="left-panel">
      <div class="logo-container">
        <img src="/img/cf_circle.png" alt="Carolina's Finest Camp Logo" class="cf-logo">
        <div class="logo-text">
          <h3>Carolina's Finest</h3>
          <p>Referee Camp</p>
        </div>
      </div>
      
      <div class="panel-divider"></div>
      
      <nav class="panel-nav">
        <button 
          @click="activeTab = 'status'" 
          :class="['nav-btn', { active: activeTab === 'status' }]"
        >
          Application Status
        </button>
        <button 
          @click="activeTab = 'roster'" 
          :class="['nav-btn', { active: activeTab === 'roster' }]"
        >
          Camp Roster
        </button>
        <button 
          @click="activeTab = 'games'" 
          :class="['nav-btn', { active: activeTab === 'games' }]"
        >
          Game Assignments
        </button>
      </nav>
      
      <div class="panel-footer">
        <router-link to="/logout" class="logout-btn">
          Logout
        </router-link>
      </div>
    </div>
    
    <!-- Main Content Area -->
    <div class="main-content">
      <div v-if="loading" class="loading">
        <p>Loading your registration details...</p>
      </div>
      
      <div v-else-if="error" class="error-message">
        <p>{{ error }}</p>
        <p>Please try logging in again or contact support if the issue persists.</p>
      </div>
      
      <div v-else>
        <!-- User Info Card - Moved from sidebar to main content -->
        <div class="user-info-card">
          <div class="user-info-header">
            <div class="user-name">{{ registration.name }}</div>
            <div :class="['status-badge', registration.payment_status]">
              {{ registration.payment_status | capitalize }}
            </div>
          </div>
          <div class="user-info-details">
            <div class="user-info-item">
              <div class="info-label">Email</div>
              <div class="info-value">{{ registration.email }}</div>
            </div>
            <div class="user-info-item">
              <div class="info-label">Phone</div>
              <div class="info-value">{{ registration.cellPhone }}</div>
            </div>
            <div class="user-info-item">
              <div class="info-label">Confirmation #</div>
              <div class="info-value">{{ registration.confirmation_number }}</div>
            </div>
          </div>
        </div>
      
        <!-- Status Tab Content -->
        <div v-if="activeTab === 'status'" class="tab-content">
          <div class="details-section">
            <h3>Registration Information</h3>
            <div class="details-grid">
              <div class="detail-item">
                <div class="detail-label">Session</div>
                <div class="detail-value">{{ registration.session }}</div>
              </div>
              <div class="detail-item">
                <div class="detail-label">Camp Date</div>
                <div class="detail-value">{{ registration.campDate }}</div>
              </div>
              <div class="detail-item">
                <div class="detail-label">Registered On</div>
                <div class="detail-value">{{ formatDate(registration.registrationDate) }}</div>
              </div>
            </div>
          </div>
          
          <div class="timeline">
            <h3>Registration Timeline</h3>
            
            <div class="timeline-item">
              <div class="timeline-date">{{ formatDate(registration.registrationDate) }}</div>
              <div class="timeline-title">Registration Submitted</div>
            </div>
            
            <div class="timeline-item">
              <div class="timeline-date">{{ formatDate(registration.payment_completed_at) }}</div>
              <div class="timeline-title">Payment Completed</div>
            </div>
            
            <div class="timeline-item" v-if="registration.confirmation_email_sent">
              <div class="timeline-date">{{ formatDate(registration.confirmation_email_sent) }}</div>
              <div class="timeline-title">Confirmation Email Sent</div>
            </div>

            <div class="timeline-item">
              <div class="timeline-date">{{ registration.campDate }}</div>
              <div class="timeline-title">Camp Date</div>
            </div>
          </div>
        </div>
        
        <!-- Roster Tab Content -->
        <div v-else-if="activeTab === 'roster'" class="tab-content">
          <h3>Camp Roster</h3>
          
          <div class="roster-grid">
            <div v-for="(participant, index) in campRoster" :key="index" class="roster-card">
              <div class="profile-image">
                <div class="profile-initial">{{ participant.name.charAt(0) }}</div>
              </div>
              <div class="participant-name">{{ participant.name }}</div>
              <div class="participant-details">
                <div class="participant-location">{{ participant.location }}</div>
                <div class="participant-role">{{ participant.role }}</div>
              </div>
            </div>
          </div>
        </div>
        
        <!-- Games Tab Content -->
        <div v-else-if="activeTab === 'games'" class="tab-content">
          <h3>Game Assignments</h3>
          
          <div class="games-list">
            <div v-if="gameAssignments.length === 0" class="no-data">
              Game assignments will be posted closer to the camp date.
            </div>
            <table v-else class="data-table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Court</th>
                  <th>Teams</th>
                  <th>Position</th>
                  <th>Evaluator</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(game, index) in gameAssignments" :key="index">
                  <td>{{ game.date }}</td>
                  <td>{{ game.time }}</td>
                  <td>{{ game.court }}</td>
                  <td>{{ game.teams }}</td>
                  <td>{{ game.position }}</td>
                  <td>{{ game.evaluator }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        
        <div class="action-section">
          <router-link :to="{ name: 'receipt', query: { confirmation: registration.confirmation_number }}" class="btn">View Receipt</router-link>
          <router-link to="/logout" class="btn secondary">Logout</router-link>
          <a :href="'mailto:camp@carolinasfinestrefereecamp.com?subject=Registration%20Inquiry%20' + registration.confirmation_number" class="btn secondary">Contact Support</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { getConfirmationNumber, isAuthenticated, getAuthToken } from '../services/auth';

// Helper to get the API URL based on current hostname
function getApiUrl() {
  const hostname = window.location.hostname;
  return `http://${hostname}:5000`;
}

export default {
  name: 'StatusView',
  data() {
    return {
      registration: {},
      loading: true,
      error: null,
      activeTab: 'status',
      campRoster: [
        { name: 'John Smith', location: 'Charlotte, NC', experience: '5 years', role: 'Participant' },
        { name: 'Maria Johnson', location: 'Raleigh, NC', experience: '8 years', role: 'Participant' },
        { name: 'Robert Williams', location: 'Charleston, SC', experience: '3 years', role: 'Participant' },
        { name: 'Sarah Davis', location: 'Columbia, SC', experience: '6 years', role: 'Participant' },
        { name: 'Michael Brown', location: 'Augusta, GA', experience: '10 years', role: 'Instructor' },
        { name: 'Lisa Taylor', location: 'Asheville, NC', experience: '7 years', role: 'Participant' },
        { name: 'David Miller', location: 'Greenville, SC', experience: '4 years', role: 'Participant' },
        { name: 'Jennifer Wilson', location: 'Charlotte, NC', experience: '12 years', role: 'Instructor' },
        { name: 'Thomas Moore', location: 'Wilmington, NC', experience: '5 years', role: 'Participant' },
        { name: 'Emily Clark', location: 'Durham, NC', experience: '6 years', role: 'Participant' }
      ],
      gameAssignments: [
        { date: '06/15/2023', time: '9:00 AM', court: 'Court 1', teams: 'Team A vs Team B', position: 'Referee', evaluator: 'Coach Johnson' },
        { date: '06/15/2023', time: '11:30 AM', court: 'Court 3', teams: 'Team C vs Team D', position: 'Umpire', evaluator: 'Coach Wilson' },
        { date: '06/16/2023', time: '10:15 AM', court: 'Court 2', teams: 'Team E vs Team F', position: 'Referee', evaluator: 'Coach Smith' },
        { date: '06/16/2023', time: '2:00 PM', court: 'Court 1', teams: 'Team G vs Team H', position: 'Umpire', evaluator: 'Coach Davis' },
        { date: '06/17/2023', time: '9:45 AM', court: 'Court 4', teams: 'Team I vs Team J', position: 'Referee', evaluator: 'Coach Brown' }
      ]
    };
  },
  filters: {
    capitalize(value) {
      if (!value) return '';
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
  },
  created() {
    // Get confirmation from route params or stored value
    let confirmationNumber = this.$route.params.confirmation;
    
    if (!confirmationNumber && isAuthenticated()) {
      confirmationNumber = getConfirmationNumber();
    }
    
    if (confirmationNumber) {
      this.fetchRegistrationDetails(confirmationNumber);
    } else {
      this.error = 'No confirmation number provided. Please login again.';
      this.loading = false;
      // Redirect to login after a brief delay
      setTimeout(() => {
        this.$router.push({ name: 'login' });
      }, 2000);
    }
  },
  methods: {
    async fetchRegistrationDetails(confirmationNumber) {
      try {
        console.log(`Fetching registration for confirmation: ${confirmationNumber}`);
        
        // Get the token first to ensure we're authenticated
        const token = getAuthToken();
        console.log(`Auth token status: ${token ? 'Present' : 'Not available'}`);
        
        const apiUrl = getApiUrl();
        
        // If we have a token, try to get registration details directly
        if (token) {
          try {
            console.log('Using token to fetch full registration details');
            
            // Try to get full details with token
            const regResponse = await axios.get(`${apiUrl}/api/registration/${confirmationNumber}`, {
              headers: {
                'Authorization': `Bearer ${token}`
              }
            });
            
            console.log('Registration details fetched successfully');
            this.registration = regResponse.data;
            this.loading = false;
            return;
          } catch (tokenError) {
            console.error('Error with token authentication:', tokenError);
            // If token authentication fails, fall back to check-registration
          }
        }
        
        // Fallback - try the check-registration endpoint which doesn't require authentication
        console.log('Falling back to check-registration endpoint');
        const checkResponse = await axios.post(`${apiUrl}/api/check-registration`, {
          confirmationNumber
        });
        
        if (checkResponse.data.status === 'success') {
          console.log('Registration found via check-registration');
          this.registration = checkResponse.data.data;
        } else {
          throw new Error(checkResponse.data.message || 'Registration not found');
        }
        
        this.loading = false;
      } catch (error) {
        console.error('Error fetching registration details:', error);
        
        if (error.response) {
          console.error('Error response status:', error.response.status);
          console.error('Error response data:', error.response.data);
          
          if (error.response.status === 401) {
            this.error = 'Your session has expired. Please log in again.';
            // Clear auth data since it's invalid
            this.clearAndRedirect();
            return;
          }
        }
        
        this.error = error.message || 'Unable to retrieve your registration details. Please try logging in again.';
        this.loading = false;
      }
    },
    clearAndRedirect() {
      // Import and use the logout function to clear auth data
      import('../services/auth').then(({ logout }) => {
        logout();
        // Redirect to login after a brief delay
        setTimeout(() => {
          this.$router.push({ name: 'login' });
        }, 2000);
      });
    },
    formatDate(dateString) {
      if (!dateString) return 'N/A';
      
      const date = new Date(dateString);
      if (isNaN(date.getTime())) return dateString; // Return as is if not a valid date
      
      return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZoneName: 'short'
      }).format(date);
    }
  }
};
</script>

<style scoped>
.status-page {
  display: flex;
  min-height: 100vh;
  background-color: #f5f5f5;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
}

/* Left Panel - Navigation only */
.left-panel {
  width: 220px;
  background-color: #2c3e50;
  color: #f5f5f5;
  display: flex;
  flex-direction: column;
}

.logo-container {
  display: flex;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.cf-logo {
  width: 36px;
  height: 36px;
  border-radius: 6px;
}

.logo-text {
  margin-left: 12px;
}

.logo-text h3 {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  line-height: 1.2;
}

.logo-text p {
  font-size: 12px;
  margin: 0;
  opacity: 0.7;
}

.panel-divider {
  height: 1px;
  background-color: rgba(255, 255, 255, 0.05);
  margin: 0;
}

.panel-nav {
  display: flex;
  flex-direction: column;
  padding: 10px;
  flex-grow: 1;
}

.nav-btn {
  background: none;
  border: none;
  text-align: left;
  padding: 10px;
  margin-bottom: 5px;
  color: rgba(255, 255, 255, 0.7);
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
  font-size: 13px;
}

.nav-btn:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: #f5f5f5;
}

.nav-btn.active {
  background-color: rgba(255, 255, 255, 0.15);
  color: white;
}

.panel-footer {
  padding: 15px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.logout-btn {
  display: block;
  width: 100%;
  padding: 8px;
  background-color: rgba(255, 255, 255, 0.1);
  color: #f5f5f5;
  border-radius: 4px;
  text-decoration: none;
  font-size: 13px;
  text-align: center;
  transition: all 0.2s;
}

.logout-btn:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

/* Main Content Area */
.main-content {
  flex: 1;
  padding: 30px;
  overflow-y: auto;
  color: #333;
}

/* User Info Card - New component for main content */
.user-info-card {
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  padding: 20px;
  margin-bottom: 20px;
}

.user-info-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.user-name {
  font-size: 18px;
  font-weight: 500;
  color: #2c3e50;
}

.user-info-details {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.user-info-item {
  flex: 1;
  min-width: 200px;
}

.info-label {
  font-size: 12px;
  color: #666;
  margin-bottom: 4px;
}

.info-value {
  font-size: 14px;
}

.tab-content {
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  padding: 25px;
  margin-bottom: 20px;
}

.status-badge {
  display: inline-block;
  padding: 5px 12px;
  border-radius: 12px;
  font-weight: 500;
  text-align: center;
  font-size: 13px;
}

.status-badge.pending {
  background-color: #fff8e1;
  color: #f57f17;
}

.status-badge.completed {
  background-color: #e8f5e9;
  color: #2e7d32;
}

.status-badge.rejected {
  background-color: #ffebee;
  color: #c62828;
}

h2 {
  font-size: 18px;
  font-weight: 500;
  color: #333;
  margin-top: 0;
}

h3 {
  font-size: 16px;
  font-weight: 500;
  color: #333;
  margin-top: 0;
  margin-bottom: 15px;
  padding-bottom: 8px;
  border-bottom: 1px solid #eee;
}

.details-section {
  margin-bottom: 25px;
}

.details-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 15px;
}

.detail-item {
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 4px;
}

.detail-label {
  font-size: 12px;
  color: #666;
  margin-bottom: 4px;
}

.detail-value {
  font-size: 14px;
}

.timeline {
  margin: 25px 0;
}

.timeline-item {
  padding-left: 20px;
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-left: 1px solid #ddd;
}

.timeline-item:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
}

.timeline-date {
  font-size: 12px;
  color: #666;
  margin-bottom: 3px;
}

.timeline-title {
  font-size: 14px;
  color: #333;
}

.data-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 13px;
}

.data-table th {
  background-color: #f5f5f5;
  padding: 10px;
  text-align: left;
  font-weight: 500;
  color: #333;
}

.data-table td {
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.data-table tr:last-child td {
  border-bottom: none;
}

.no-data {
  text-align: center;
  padding: 20px;
  color: #666;
  font-style: italic;
  background-color: #f9f9f9;
  border-radius: 4px;
}

.loading, .error-message {
  margin: 20px 0;
  padding: 15px;
  border-radius: 4px;
  text-align: center;
}

.loading {
  background-color: #f9f9f9;
  color: #666;
}

.error-message {
  background-color: #ffebee;
  color: #c62828;
}

.loading-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  opacity: 0.8;
}

.loading-spinner {
  width: 14px;
  height: 14px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top-color: #666;
  animation: spin 1s linear infinite;
  margin-right: 8px;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

.action-section {
  margin-top: 20px;
}

.btn {
  display: inline-block;
  background-color: #546e7a;
  color: white;
  padding: 8px 12px;
  text-decoration: none;
  border-radius: 4px;
  font-size: 13px;
  margin-right: 8px;
  margin-bottom: 8px;
  transition: all 0.2s;
}

.btn:hover {
  background-color: #455a64;
}

.btn.secondary {
  background-color: #e0e0e0;
  color: #333;
}

.btn.secondary:hover {
  background-color: #bdbdbd;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .status-page {
    flex-direction: column;
  }
  
  .left-panel {
    width: 100%;
  }
  
  .panel-nav {
    flex-direction: row;
    flex-wrap: wrap;
  }
  
  .nav-btn {
    flex: 1 0 auto;
    text-align: center;
    margin-right: 5px;
  }
  
  .main-content {
    padding: 15px;
  }
  
  .details-grid {
    grid-template-columns: 1fr;
  }
  
  .user-info-details {
    flex-direction: column;
    gap: 10px;
  }
  
  .user-info-item {
    min-width: 100%;
  }
}

/* Roster Grid Styling */
.roster-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.roster-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
  border-radius: 6px;
  padding: 15px;
  transition: all 0.2s;
}

.roster-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}

.profile-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #546e7a;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
}

.profile-initial {
  font-size: 32px;
  font-weight: 500;
  color: white;
}

.participant-name {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 6px;
  color: #2c3e50;
}

.participant-details {
  font-size: 12px;
  color: #666;
  text-align: center;
}

.participant-location {
  margin-bottom: 2px;
}

.participant-role {
  font-style: italic;
}

/* Responsive adjustments for roster grid */
@media (max-width: 768px) {
  .roster-grid {
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
    gap: 15px;
  }
  
  .profile-image {
    width: 60px;
    height: 60px;
  }
  
  .profile-initial {
    font-size: 24px;
  }
}
</style> 