<template>
  <div class="max-w-6xl mx-auto p-6 md:p-8">
    <transition appear name="slide-fade" mode="out-in">
      <form @submit.prevent="submitForm" class="space-y-6">
        <!-- For debugging -->
        <div v-if="false" class="bg-gray-100 p-4 mb-4 rounded">
          <pre>{{ JSON.stringify(errors, null, 2) }}</pre>
        </div>

        <!-- Validation error summary -->
        <div v-if="showValidationSummary && Object.keys(errors).length > 0" class="bg-red-100 text-red-700 p-4 rounded-md mb-6 border border-red-300">
          <div class="flex justify-between items-start">
            <div>
              <p class="font-medium">Please fix the following {{ Object.keys(errors).length }} {{ Object.keys(errors).length === 1 ? 'error' : 'errors' }} to continue:</p>
              <ul class="list-disc pl-8 mt-2 space-y-1 text-sm">
                <li v-if="errors.headshot"><a href="#" @click.prevent="scrollToElement('headshot')" class="hover:underline">{{ errors.headshot }}</a></li>
                <li v-if="errors.firstName"><a href="#" @click.prevent="scrollToElement('firstName')" class="hover:underline">{{ errors.firstName }}</a></li>
                <li v-if="errors.lastName"><a href="#" @click.prevent="scrollToElement('lastName')" class="hover:underline">{{ errors.lastName }}</a></li>
                <li v-if="errors.email"><a href="#" @click.prevent="scrollToElement('email')" class="hover:underline">{{ errors.email }}</a></li>
                <li v-if="errors.cellPhone"><a href="#" @click.prevent="scrollToElement('cellPhone')" class="hover:underline">{{ errors.cellPhone }}</a></li>
                <li v-if="errors.address"><a href="#" @click.prevent="scrollToElement('address')" class="hover:underline">{{ errors.address }}</a></li>
                <li v-if="errors.city"><a href="#" @click.prevent="scrollToElement('city')" class="hover:underline">{{ errors.city }}</a></li>
                <li v-if="errors.state"><a href="#" @click.prevent="scrollToElement('state')" class="hover:underline">{{ errors.state }}</a></li>
                <li v-if="errors.zip"><a href="#" @click.prevent="scrollToElement('zip')" class="hover:underline">{{ errors.zip }}</a></li>
                <li v-if="errors.yearsOfExperience"><a href="#" @click.prevent="scrollToElement('yearsOfExperience')" class="hover:underline">{{ errors.yearsOfExperience }}</a></li>
                <li v-if="errors.yearsThreePerson"><a href="#" @click.prevent="scrollToElement('yearsThreePerson')" class="hover:underline">{{ errors.yearsThreePerson }}</a></li>
                <li v-if="errors.highestWorked"><a href="#" @click.prevent="scrollToElement('highestWorked')" class="hover:underline">{{ errors.highestWorked }}</a></li>
                <li v-if="errors.yearsAtLevel"><a href="#" @click.prevent="scrollToElement('yearsAtLevel')" class="hover:underline">{{ errors.yearsAtLevel }}</a></li>
                <li v-if="errors.shirtSize"><a href="#" @click.prevent="scrollToElement('shirtSize')" class="hover:underline">{{ errors.shirtSize }}</a></li>
                <li v-if="errors.form" class="text-red-800 font-medium">{{ errors.form }}</li>
              </ul>
            </div>
            <button type="button" @click="scrollToTop" class="bg-red-200 hover:bg-red-300 text-red-800 px-3 py-1 rounded text-sm">Return to top</button>
          </div>
        </div>

        <!-- Headshot Upload -->
        <div class="flex flex-col sm:flex-row items-center gap-5 mb-6">
          <div v-if="headshotPreview" class="relative flex-shrink-0">
            <div class="w-32 h-32 rounded-full overflow-hidden border-2 border-gray-200 shadow-sm">
              <img :src="headshotPreview" class="object-cover w-full h-full" alt="Headshot preview">
            </div>
            <button type="button" @click="removeHeadshot"
              class="absolute -top-2 -right-2 bg-white text-red-500 rounded-full p-1.5 shadow-md border border-gray-200 hover:bg-red-50 transition-colors"
              title="Remove headshot">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24"
                stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <div v-else-if="isProcessingImage" class="flex-shrink-0">
            <div
              class="w-32 h-32 rounded-full border-2 border-dashed border-gray-300 flex items-center justify-center bg-gray-50">
              <div class="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
            </div>
          </div>
          <div v-else class="flex-shrink-0">
            <div
              class="w-32 h-32 rounded-full border-2 border-dashed border-gray-300 flex items-center justify-center bg-gray-50">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-gray-400" fill="none" viewBox="0 0 24 24"
                stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                  d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
              </svg>
            </div>
          </div>
          <div class="flex-grow">
            <label for="headshot" class="inline-block text-sm font-medium text-gray-700 mb-2">
              Upload Headshot <span class="text-red-500">*</span>
            </label>
            <div class="flex items-center">
              <label for="headshot"
                class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 cursor-pointer transition-colors">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z" />
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 13a3 3 0 11-6 0 3 3 0 016 0z" />
                </svg>
                Choose photo
              </label>
              <input id="headshot" type="file" class="hidden" accept="image/jpeg, image/png" @change="uploadHeadshot" />
              <span class="ml-3 text-xs text-gray-500">JPG or PNG, max 2MB</span>
            </div>
            <p class="text-xs text-gray-500 mt-1">Professional headshot for your camp badge</p>
            <p v-if="errors.headshot" class="text-xs text-red-500 font-medium mt-2 bg-red-50 p-2 rounded-md">{{ errors.headshot }}</p>
          </div>
        </div>

        <!-- Personal Information -->
        <div class="border border-gray-200 rounded-lg overflow-hidden">
          <div class="bg-gray-50 px-4 py-3 border-b border-gray-200">
            <h3 class="text-md font-medium text-gray-700">Personal Information</h3>
          </div>
          <div class="p-4">
            <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
              <div>
                <label for="firstName" class="block text-sm text-gray-700 mb-1">First Name <span class="text-red-500">*</span></label>
                <input type="text" id="firstName" v-model="formData.firstName"
                  :class="['w-full p-2 text-sm border rounded', errors.firstName ? 'border-red-500 bg-red-50' : 'border-gray-300']" required>
                <p v-if="errors.firstName" class="text-xs text-red-500 mt-1">{{ errors.firstName }}</p>
              </div>
              <div>
                <label for="lastName" class="block text-sm text-gray-700 mb-1">Last Name <span class="text-red-500">*</span></label>
                <input type="text" id="lastName" v-model="formData.lastName"
                  :class="['w-full p-2 text-sm border rounded', errors.lastName ? 'border-red-500 bg-red-50' : 'border-gray-300']" required>
                <p v-if="errors.lastName" class="text-xs text-red-500 mt-1">{{ errors.lastName }}</p>
              </div>
            </div>

            <div class="space-y-4">
              <div>
                <label for="email" class="block text-sm text-gray-700 mb-1">Email <span class="text-red-500">*</span></label>
                <input type="email" id="email" v-model="formData.email"
                  :class="['w-full p-2 text-sm border rounded', errors.email ? 'border-red-500 bg-red-50' : 'border-gray-300']" required>
                <p v-if="errors.email" class="text-xs text-red-500 mt-1">{{ errors.email }}</p>
              </div>

              <div>
                <label for="cellPhone" class="block text-sm text-gray-700 mb-1">Cell Phone <span class="text-red-500">*</span></label>
                <input type="text" id="cellPhone" v-model="formData.cellPhone" @input="handlePhoneInput"
                  :class="['w-full p-2 text-sm border rounded', errors.cellPhone ? 'border-red-500 bg-red-50' : 'border-gray-300']" required maxlength="14"
                  placeholder="(555) 555-5555">
                <p v-if="errors.cellPhone" class="text-xs text-red-500 mt-1">{{ errors.cellPhone }}</p>
              </div>

              <div>
                <label for="address" class="block text-sm text-gray-700 mb-1">Address <span class="text-red-500">*</span></label>
                <input type="text" id="address" v-model="formData.address"
                  :class="['w-full p-2 text-sm border rounded', errors.address ? 'border-red-500 bg-red-50' : 'border-gray-300']" required>
                <p v-if="errors.address" class="text-xs text-red-500 mt-1">{{ errors.address }}</p>
              </div>

              <div class="grid grid-cols-1 sm:grid-cols-3 gap-4">
                <div>
                  <label for="city" class="block text-sm text-gray-700 mb-1">City <span class="text-red-500">*</span></label>
                  <input type="text" id="city" v-model="formData.city"
                    :class="['w-full p-2 text-sm border rounded', errors.city ? 'border-red-500 bg-red-50' : 'border-gray-300']" required>
                  <p v-if="errors.city" class="text-xs text-red-500 mt-1">{{ errors.city }}</p>
                </div>
                <div>
                  <label for="state" class="block text-sm text-gray-700 mb-1">State <span class="text-red-500">*</span></label>
                  <select :class="['w-full p-2 text-sm border rounded', errors.state ? 'border-red-500 bg-red-50' : 'border-gray-300']" id="state" v-model="formData.state"
                    required>
                    <option value="---">Select State</option>
                    <option v-for="state in states" :key="state" :value="state">{{ state }}</option>
                  </select>
                  <p v-if="errors.state" class="text-xs text-red-500 mt-1">{{ errors.state }}</p>
                </div>
                <div>
                  <label for="zip" class="block text-sm text-gray-700 mb-1">Zip <span class="text-red-500">*</span></label>
                  <input type="text" id="zip" v-model="formData.zip"
                    :class="['w-full p-2 text-sm border rounded', errors.zip ? 'border-red-500 bg-red-50' : 'border-gray-300']" required>
                  <p v-if="errors.zip" class="text-xs text-red-500 mt-1">{{ errors.zip }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Experience Information -->
        <div class="border border-gray-200 rounded-lg overflow-hidden">
          <div class="bg-gray-50 px-4 py-3 border-b border-gray-200">
            <h3 class="text-md font-medium text-gray-700">Experience</h3>
          </div>
          <div class="p-4">
            <div class="mb-4">
              <label for="yearsOfExperience" class="block text-sm text-gray-700 mb-1">Years of Experience <span class="text-red-500">*</span></label>
              <select :class="['w-full p-2 text-sm border rounded', errors.yearsOfExperience ? 'border-red-500 bg-red-50' : 'border-gray-300']" id="yearsOfExperience"
                v-model="formData.yearsOfExperience" required>
                <option value="">Select Years</option>
                <option v-for="option in numberOptions" :key="option" :value="option">{{ option }}</option>
              </select>
              <p v-if="errors.yearsOfExperience" class="text-xs text-red-500 mt-1">{{ errors.yearsOfExperience }}</p>
            </div>

            <div class="mb-4">
              <label for="yearsThreePerson" class="block text-sm text-gray-700 mb-1">Years of Three Person
                Mechanics <span class="text-red-500">*</span></label>
              <select :class="['w-full p-2 text-sm border rounded', errors.yearsThreePerson ? 'border-red-500 bg-red-50' : 'border-gray-300']" id="yearsThreePerson"
                v-model="formData.yearsThreePerson" required>
                <option value="">Select Years</option>
                <option v-for="option in numberOptions" :key="option" :value="option">{{ option }}</option>
              </select>
              <p v-if="errors.yearsThreePerson" class="text-xs text-red-500 mt-1">{{ errors.yearsThreePerson }}</p>
            </div>

            <div class="mb-4">
              <label for="highestWorked" class="block text-sm text-gray-700 mb-1">Highest Level Worked <span class="text-red-500">*</span></label>
              <select :class="['w-full p-2 text-sm border rounded', errors.highestWorked ? 'border-red-500 bg-red-50' : 'border-gray-300']" id="highestWorked"
                v-model="formData.highestWorked" required>
                <option value="">Select Level</option>
                <option value="D1">D1</option>
                <option value="D2">D2</option>
                <option value="D3">D3</option>
                <option value="NAIA/JUCO">NAIA/JUCO</option>
                <option value="High School">High School</option>
                <option value="None">None</option>
              </select>
              <p v-if="errors.highestWorked" class="text-xs text-red-500 mt-1">{{ errors.highestWorked }}</p>
            </div>

            <div class="mb-5 mt-3 p-3 bg-gray-50 border border-gray-300 rounded-md"
              v-if="formData.highestWorked && formData.highestWorked !== 'None'">
              <label for="yearsAtLevel" class="block text-sm font-medium text-gray-700 mb-1">
                Years at {{ formData.highestWorked }} <span class="text-red-500">*</span>
              </label>
              <select :class="['w-full p-2 text-sm border border-gray-300 rounded', errors.yearsAtLevel ? 'border-red-500 bg-red-50' : 'border-gray-300']" id="yearsAtLevel"
                v-model="formData.yearsAtLevel" required>
                <option value="">Select Years</option>
                <option v-for="option in numberOptions" :key="option" :value="option">{{ option }}</option>
              </select>
              <p class="text-xs text-gray-500 mt-2">Please specify your experience at this level</p>
              <p v-if="errors.yearsAtLevel" class="text-xs text-red-500 mt-1">{{ errors.yearsAtLevel }}</p>
            </div>

            <div class="mb-6">
              <label for="shirtSize" class="block text-sm text-gray-700 mb-1">Shirt Size <span class="text-red-500">*</span></label>
              <select :class="['w-full p-2 text-sm border rounded', errors.shirtSize ? 'border-red-500 bg-red-50' : 'border-gray-300']" id="shirtSize"
                v-model="formData.shirtSize" required>
                <option value="">Select Size</option>
                <optgroup label="Men's Sizes">
                  <option value="Men - S">Men - Small</option>
                  <option value="Men - M">Men - Medium</option>
                  <option value="Men - L">Men - Large</option>
                  <option value="Men - XL">Men - X-Large</option>
                  <option value="Men - XXL">Men - XX-Large</option>
                  <option value="Men - XXXL">Men - XXX-Large</option>
                </optgroup>
                <optgroup label="Women's Sizes">
                  <option value="Women - S">Women - Small</option>
                  <option value="Women - M">Women - Medium</option>
                  <option value="Women - L">Women - Large</option>
                  <option value="Women - XL">Women - X-Large</option>
                  <option value="Women - XXL">Women - XX-Large</option>
                </optgroup>
              </select>
              <p v-if="errors.shirtSize" class="text-xs text-red-500 mt-1">{{ errors.shirtSize }}</p>
            </div>

            <!-- Conferences Worked -->
            <div class="border-t border-gray-200 pt-5 mt-5">
              <h4 class="text-md font-medium text-gray-700 mb-3">Conferences Worked <span class="text-red-500">*</span></h4>
              <p class="text-gray-500 text-sm mb-4">Select all conferences you currently work:</p>

              <!-- Conference Selection -->
              <div class="mb-4">
                <div class="mb-4" v-if="selectedConferences.length > 0">
                  <div class="bg-blue-50 border border-blue-200 rounded-lg p-3 mb-3">
                    <h5 class="text-blue-700 font-medium mb-2">Selected Conferences</h5>
                    <div class="grid grid-cols-1 gap-2">
                      <div v-for="(conf, index) in selectedConferences" :key="index"
                        class="flex items-center gap-2 p-2 bg-white rounded-md border border-blue-100 shadow-sm">
                        <div class="flex-grow">
                          <div class="font-medium text-sm">{{ conf.name }}</div>
                          <div class="text-xs text-gray-500">{{ getConferenceType(conf.name) }}</div>
                        </div>
                        <div class="w-28">
                          <label class="block text-xs text-gray-700 mb-1">Years</label>
                          <select class="w-full py-1 px-2 text-sm border border-gray-300 rounded" v-model="conf.years">
                            <option value="">Select</option>
                            <option v-for="num in 40" :key="num" :value="num">{{ num }}</option>
                          </select>
                        </div>
                        <button type="button" @click="removeConference(index)"
                          class="p-1 text-red-600 hover:bg-red-50 rounded-full">
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M6 18L18 6M6 6l12 12" />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="flex gap-3">
                  <select v-model="selectedConference" @change="handleConferenceSelection"
                    class="w-full p-2 text-sm border border-gray-300 rounded">
                    <option value="">Select a Conference</option>
                    <optgroup label="NCAA Division I">
                      <option v-for="conf in d1Conferences" :key="conf" :value="conf">{{ conf }}</option>
                    </optgroup>
                    <optgroup label="NCAA Division II">
                      <option v-for="conf in d2Conferences" :key="conf" :value="conf">{{ conf }}</option>
                    </optgroup>
                    <optgroup label="NCAA Division III">
                      <option v-for="conf in d3Conferences" :key="conf" :value="conf">{{ conf }}</option>
                    </optgroup>
                    <optgroup label="NAIA & JUCO">
                      <option v-for="conf in naiaJucoConferences" :key="conf" :value="conf">{{ conf }}</option>
                    </optgroup>
                    <optgroup label="High School">
                      <option v-for="conf in highSchoolConferences" :key="conf" :value="conf">{{ conf }}</option>
                    </optgroup>
                    <option value="custom">Other (Custom)</option>
                  </select>
                </div>

                <div v-if="selectedConference === 'custom'" class="mt-3 flex gap-3">
                  <input type="text" v-model="otherConference"
                    class="flex-grow p-2 text-sm border border-gray-300 rounded" placeholder="Enter conference name">
                  <button type="button" @click="addCustomConference"
                    class="py-2 px-4 text-sm font-medium text-white bg-blue-600 rounded hover:bg-blue-700"
                    :disabled="!otherConference">
                    Add Custom
                  </button>
                </div>
              </div>
            </div>

            <!-- Tournament Experience -->
            <div class="mt-6 pt-5 border-t border-gray-200">
              <label for="tournamentExperience" class="block text-sm font-medium text-gray-700 mb-2">Tournament
                Experience <span class="text-red-500">*</span></label>
              <textarea id="tournamentExperience" v-model="formData.tournamentExperience"
                class="w-full p-2 text-sm border border-gray-300 rounded" rows="3"
                placeholder="Describe any tournament experience you have" required></textarea>
              <p class="text-xs text-gray-500 mt-1">Please list any tournaments you have worked and your role</p>
            </div>
          </div>
        </div>

        <button type="submit"
          class="w-full py-3 px-4 text-md font-medium text-white bg-blue-900 rounded hover:bg-blue-800 transition-colors"
          :disabled="processingState">
          <div v-if="processingState" class="flex items-center justify-center">
            <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            Processing...
          </div>
          <span v-else>Submit Registration</span>
        </button>
      </form>
    </transition>
  </div>
</template>

<script>
import { ref, reactive, computed, nextTick, onMounted, onUnmounted } from 'vue'
import axios from 'axios'
import api from '@/services/api'
import { useRouter } from 'vue-router'
import { createCheckoutSession } from './stripeCheckout'

export default {
  name: 'RegistrationForm',
  props: {
    selectedCamp: {
      type: Object,
      default: null
    },
    availableCamps: {
      type: Array,
      default: () => []
    },
    isSpecialRegistration: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const router = useRouter()
    const showOtherD1 = ref(false)
    const showOtherD2 = ref(false)
    const showOtherD3 = ref(false)
    const showOtherNAIAJUCO = ref(false)
    const showHighSchoolState = ref(false)

    // Listen for keyboard shortcut (removed)
    onMounted(() => {
      // No keyboard shortcuts needed
    });

    onUnmounted(() => {
      // No event listeners to remove
    });

    // Camp selection
    const selectedCampId = ref('')
    const computedSelectedCamp = computed(() => {
      if (props.selectedCamp) return props.selectedCamp
      return props.availableCamps.find(camp => camp.id === selectedCampId.value) || null
    })

    // Form validation errors
    const errors = ref({
      firstName: '',
      lastName: '',
      address: '',
      city: '',
      state: '',
      zip: '',
      email: '',
      cellPhone: '',
      yearsOfExperience: '',
      yearsThreePerson: '',
      highestWorked: '',
      shirtSize: '',
      campSelection: '',
      headshot: '',
      yearsAtLevel: '',
      form: ''
    })

    // Headshot handling
    const headshot = ref(null)
    const headshotPreview = ref(null)
    const isProcessingImage = ref(false)

    const uploadHeadshot = async (event) => {
      const file = event.target.files[0]
      if (!file) return

      // Validate file type and size
      const validTypes = ['image/jpeg', 'image/png']
      const maxSize = 2 * 1024 * 1024 // 2MB

      if (!validTypes.includes(file.type)) {
        alert('Please upload a JPG or PNG image')
        return
      }

      if (file.size > maxSize) {
        alert('File size must be less than 2MB')
        return
      }

      isProcessingImage.value = true

      try {
        // Create preview URL
        headshotPreview.value = URL.createObjectURL(file)
        headshot.value = file
      } finally {
        isProcessingImage.value = false
      }
    }

    const removeHeadshot = () => {
      headshot.value = null
      headshotPreview.value = null
      // Reset the file input
      const input = document.getElementById('headshot')
      if (input) input.value = ''
    }

    // Conference selection
    const selectedConference = ref('')
    const selectedConferences = ref([])
    const otherConference = ref('')

    const formData = reactive({
      firstName: '',
      lastName: '',
      address: '',
      city: '',
      state: '',
      zip: '',
      email: '',
      cellPhone: '',
      yearsOfExperience: '',
      yearsThreePerson: '',
      highestWorked: '',
      yearsAtLevel: '',
      shirtSize: '',
      tournamentExperience: ''
    })

    // Phone number formatting function
    const formatPhoneNumber = (value) => {
      // Remove all non-numeric characters
      const numbers = value.replace(/\D/g, '')
      
      // Format the number
      if (numbers.length <= 3) {
        return numbers
      } else if (numbers.length <= 6) {
        return `(${numbers.slice(0, 3)}) ${numbers.slice(3)}`
      } else {
        return `(${numbers.slice(0, 3)}) ${numbers.slice(3, 6)}-${numbers.slice(6, 10)}`
      }
    }

    // Handle phone input changes
    const handlePhoneInput = (event) => {
      const formatted = formatPhoneNumber(event.target.value)
      formData.cellPhone = formatted
    }

    // All available conferences
    const conferences = {
      d1: [
        'America East Conference',
        'American Athletic Conference',
        'ASUN Conference',
        'Atlantic 10 Conference',
        'Atlantic Coast Conference (ACC)',
        'Big 12 Conference',
        'Big East Conference',
        'Big Sky Conference',
        'Big South Conference',
        'Big Ten Conference',
        'Big West Conference',
        'Colonial Athletic Association (CAA)',
        'Conference USA',
        'Horizon League',
        'Ivy League',
        'Metro Atlantic Athletic Conference',
        'Mid-American Conference',
        'Mid-Eastern Athletic Conference',
        'Missouri Valley Conference',
        'Mountain West Conference',
        'Northeast Conference',
        'Ohio Valley Conference',
        'Pac-12 Conference',
        'Patriot League',
        'Southeastern Conference (SEC)',
        'Southern Conference',
        'Southland Conference',
        'Southwestern Athletic Conference',
        'Summit League',
        'Sun Belt Conference',
        'West Coast Conference',
        'Western Athletic Conference'
      ],
      d2: [
        'California Collegiate Athletic Association',
        'Central Atlantic Collegiate Conference',
        'Central Intercollegiate Athletic Association',
        'Conference Carolinas',
        'East Coast Conference',
        'Great American Conference',
        'Great Lakes Intercollegiate Athletic Conference',
        'Great Lakes Valley Conference',
        'Great Midwest Athletic Conference',
        'Great Northwest Athletic Conference',
        'Gulf South Conference',
        'Lone Star Conference',
        'Mid-America Intercollegiate Athletics Association',
        'Mountain East Conference',
        'Northeast-10 Conference',
        'Northern Sun Intercollegiate Conference',
        'Pacific West Conference',
        'Peach Belt Conference',
        'Pennsylvania State Athletic Conference',
        'Rocky Mountain Athletic Conference',
        'South Atlantic Conference (SAC)',
        'Southern Intercollegiate Athletic Conference',
        'Sunshine State Conference'
      ],
      d3: [
        'Allegheny Mountain Collegiate Conference',
        'American Rivers Conference',
        'American Southwest Conference',
        'Atlantic East Conference',
        'Centennial Conference',
        'City University of New York Athletic Conference',
        'Coast-To-Coast Athletic Conference',
        'College Conference of Illinois and Wisconsin',
        'Colonial States Athletic Conference',
        'Commonwealth Coast Conference',
        'Empire 8',
        'Great Northeast Athletic Conference',
        'Heartland Collegiate Athletic Conference',
        'Landmark Conference',
        'Liberty League',
        'Little East Conference',
        'Massachusetts State Collegiate Athletic Conference',
        'Michigan Intercollegiate Athletic Association',
        'Middle Atlantic Conference',
        'Midwest Conference',
        'Minnesota Intercollegiate Athletic Conference',
        'New England Collegiate Conference',
        'New England Small College Athletic Conference',
        'New England Women\'s and Men\'s Athletic Conference',
        'New Jersey Athletic Conference',
        'North Atlantic Conference',
        'North Coast Athletic Conference',
        'North Eastern Athletic Conference',
        'Northern Athletics Collegiate Conference',
        'Northwest Conference',
        'Ohio Athletic Conference',
        'Old Dominion Athletic Conference',
        'Presidents\' Athletic Conference',
        'Skyline Conference',
        'Southern Athletic Association',
        'Southern California Intercollegiate Athletic Conference',
        'Southern Collegiate Athletic Conference',
        'St. Louis Intercollegiate Athletic Conference',
        'State University of New York Athletic Conference',
        'University Athletic Association',
        'Upper Midwest Athletic Conference',
        'USA South Athletic Conference',
        'Wisconsin Intercollegiate Athletic Conference'
      ],
      naia: [
        'Appalachian Athletic Conference',
        'California Pacific Conference',
        'Cascade Collegiate Conference',
        'Chicagoland Collegiate Athletic Conference',
        'Crossroads League',
        'Frontier Conference',
        'Golden State Athletic Conference',
        'Great Plains Athletic Conference',
        'Heart of America Athletic Conference',
        'Kansas Collegiate Athletic Conference',
        'Mid-South Conference',
        'North Star Athletic Association',
        'River States Conference',
        'Sooner Athletic Conference',
        'The Sun Conference',
        'JUCO Region X',
        'NJCAA Region 1',
        'NJCAA Region 2',
        'NJCAA Region 3',
        'NJCAA Region 4',
        'NJCAA Region 5',
        'NJCAA Region 6',
        'NJCAA Region 7',
        'NJCAA Region 8',
        'NJCAA Region 9'
      ],
      highSchool: [
        'High School'
      ]
    }

    // Filter out conferences that are already selected
    const conferencesFiltered = computed(() => {
      const selected = selectedConferences.value.map(conf => conf.name)

      return {
        d1: conferences.d1.filter(conf => !selected.includes(conf)),
        d2: conferences.d2.filter(conf => !selected.includes(conf)),
        d3: conferences.d3.filter(conf => !selected.includes(conf)),
        naia: conferences.naia.filter(conf => !selected.includes(conf)),
        highSchool: conferences.highSchool.filter(conf => !selected.includes(conf))
      }
    })

    const states = [
      'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FL',
      'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME',
      'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH',
      'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI',
      'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'
    ]

    // Level options for Highest Level Worked
    const levelOptions = ['D1', 'D2', 'D3', 'NAIA/JUCO', 'High School', 'None']

    // Create an array of numbers from 1 to 40 for dropdown options, converted to strings
    const numberOptions = Array.from({ length: 40 }, (_, i) => String(i + 1))

    // Conference functions
    const handleConferenceSelection = () => {
      if (selectedConference.value && selectedConference.value !== 'custom') {
        addSelectedConference();
      }
    }

    const addSelectedConference = () => {
      if (selectedConference.value && selectedConference.value !== 'custom') {
        selectedConferences.value.push({
          name: selectedConference.value,
          years: ''
        });
        selectedConference.value = '';
      }
    }

    const addConference = (confName) => {
      selectedConferences.value.push({
        name: confName,
        years: ''
      })
    }

    const addCustomConference = () => {
      if (otherConference.value.trim()) {
        selectedConferences.value.push({
          name: otherConference.value.trim(),
          years: ''
        })
        otherConference.value = ''
        selectedConference.value = ''
      }
    }

    const removeConference = (index) => {
      selectedConferences.value.splice(index, 1)
    }

    const getConferenceType = (confName) => {
      if (conferences.d1.includes(confName)) return 'D1'
      if (conferences.d2.includes(confName)) return 'D2'
      if (conferences.d3.includes(confName)) return 'D3'
      if (conferences.naia.includes(confName)) return 'NAIA/JUCO'
      if (conferences.highSchool.includes(confName)) return 'High School'
      return 'Other'
    }

    // Form submission states
    const processingState = ref(false)
    const showPaypalButtons = ref(false)
    const showValidationSummary = ref(false)

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }

    const scrollToElement = (elementId) => {
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        element.focus();
      }
    }

    const downloadPdf = (url) => {
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'registration.pdf')
      document.body.appendChild(link)
      link.click()
      link.remove()
    }

    const validateForm = () => {
      // Clear previous errors
      errors.value = {}
      
      // Validate required fields
      if (!headshot.value) {
        errors.value.headshot = 'Headshot is required'
      }
      
      if (!formData.firstName) {
        errors.value.firstName = 'First name is required'
      }
      
      if (!formData.lastName) {
        errors.value.lastName = 'Last name is required'
      }
      
      if (!formData.email) {
        errors.value.email = 'Email is required'
      } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
        errors.value.email = 'Please enter a valid email address'
      }
      
      if (!formData.cellPhone) {
        errors.value.cellPhone = 'Cell phone is required'
      } else if (formData.cellPhone.replace(/\D/g, '').length !== 10) {
        errors.value.cellPhone = 'Please enter a valid 10-digit phone number'
      }
      
      if (!formData.address) {
        errors.value.address = 'Address is required'
      }
      
      if (!formData.city) {
        errors.value.city = 'City is required'
      }
      
      if (!formData.state || formData.state === '---') {
        errors.value.state = 'State is required'
      }
      
      if (!formData.zip) {
        errors.value.zip = 'Zip code is required'
      }
      
      if (!formData.yearsOfExperience || formData.yearsOfExperience === '---') {
        errors.value.yearsOfExperience = 'Years of experience is required'
      }
      
      if (!formData.yearsThreePerson || formData.yearsThreePerson === '---') {
        errors.value.yearsThreePerson = 'Years as 3-person official is required'
      }
      
      if (!formData.highestWorked || formData.highestWorked === '---') {
        errors.value.highestWorked = 'Highest level worked is required'
      }
      
      if (!formData.yearsAtLevel || formData.yearsAtLevel === '---') {
        errors.value.yearsAtLevel = 'Years at highest level is required'
      }
      
      if (!formData.shirtSize || formData.shirtSize === '---') {
        errors.value.shirtSize = 'Shirt size is required'
      }

      // If there are errors, show validation summary and scroll to first error
      if (Object.keys(errors.value).length > 0) {
        showValidationSummary.value = true;
        
        // Scroll to the first field with an error
        const errorFields = Object.keys(errors.value);
        if (errorFields.length > 0) {
          // Wait a tick for the DOM to update with error messages
          setTimeout(() => {
            scrollToElement(errorFields[0]);
          }, 100);
        }
        
        return false;
      }
      
      // No errors
      return true
    }

    const submitForm = async () => {
      console.log('Form submission initiated')
      processingState.value = true
      errors.value = {}
      showValidationSummary.value = false
      
      // Validate the form
      const isValid = validateForm()
      
      if (!isValid) {
        processingState.value = false
        showValidationSummary.value = true
        scrollToTop()
        return
      }
      
      try {
        // Convert the form data to FormData
        const data = new FormData()
        
        // Add all form fields
        for (const [key, value] of Object.entries(formData)) {
          if (key === 'selectedConferences') continue // Skip this field as we'll handle it differently
          if (value !== null && value !== undefined) {
            data.append(key, value)
          }
        }
        
        // Add combined name field (firstName + lastName)
        data.append('name', `${formData.firstName} ${formData.lastName}`)
        
        // Add the selected camp data
        if (computedSelectedCamp.value) {
          data.append('session', computedSelectedCamp.value.name)
          data.append('campDate', computedSelectedCamp.value.dates)
          data.append('price', computedSelectedCamp.value.cost)
        }
        
        // Convert selected conferences to a string representation
        const conferencesData = selectedConferences.value.map(c => {
          return { name: c.name, years: c.years, type: getConferenceType(c.name) }
        })
        data.append('conferences', JSON.stringify(conferencesData))
        
        // Format conferences as a human-readable string
        const conferencesString = selectedConferences.value.map(c => 
          `${c.name} (${c.years} ${c.years === '1' ? 'year' : 'years'})`
        ).join(', ')
        // Replace the JSON with the formatted string
        data.delete('conferences')
        data.append('conferences', conferencesString)
        
        // Add the headshot file if exists
        if (headshot.value) {
          data.append('headshot', headshot.value)
        }
        
        // Handle special registration case (no payment)
        if (props.isSpecialRegistration) {
          // Add debugging logs
          console.log('Special registration form data:');
          Array.from(data.entries()).forEach(([key, value]) => {
            console.log(`${key}: ${value}`);
          });
          
          // Make direct API call to save registration without payment
          const response = await api.post('/api/special-registration', data, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          
          if (response.data && response.data.confirmation_number) {
            // Redirect to success page with the confirmation number
            router.push({
              path: '/success',
              query: { 
                confirmation: response.data.confirmation_number,
                special: 'true'
              }
            })
          } else {
            throw new Error('Registration failed: No confirmation number received')
          }
        } else {
          // Regular registration process with payment
          // Send the data to the server to save and create a Stripe checkout session
          const response = await api.post('/api/saveCampInformation', data, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          
          // Handle the response
          console.log('Server response:', response.data)
          
          if (response.data.code === 200 && response.data.checkout_url) {
            // Redirect to the Stripe checkout URL
            window.location.href = response.data.checkout_url;
          } else if (response.data.checkout_url) {
            // Fallback if code isn't 200 but we have a checkout URL
            window.location.href = response.data.checkout_url;
          } else {
            throw new Error(response.data.message || 'Registration failed: No checkout URL received')
          }
        }
      } catch (error) {
        console.error('Form submission error:', error)
        processingState.value = false
        
        // Handle specific error cases
        if (error.response) {
          if (error.response.status === 413) {
            errors.value.headshot = 'The uploaded file is too large. Please select a smaller image (max 2MB).'
          } else {
            errors.value.form = `Error: ${error.response.data.detail || 'Something went wrong'}`
          }
        } else {
          errors.value.form = `Error: ${error.message || 'Something went wrong'}`
        }
        
        showValidationSummary.value = true
        scrollToTop()
      }
    }

    return {
      formData,
      selectedCampId,
      computedSelectedCamp,
      availableCamps: props.availableCamps,
      selectedConferences,
      selectedConference,
      otherConference,
      addConference,
      addCustomConference,
      removeConference,
      getConferenceType,
      errors,
      validateForm,
      submitForm,
      numberOptions,
      states,
      d1Conferences: conferences.d1,
      d2Conferences: conferences.d2,
      d3Conferences: conferences.d3,
      naiaJucoConferences: conferences.naia,
      highSchoolConferences: conferences.highSchool,
      handleConferenceSelection,
      addSelectedConference,
      isProcessingImage,
      headshot,
      headshotPreview,
      uploadHeadshot,
      removeHeadshot,
      handlePhoneInput,
      processingState,
      showValidationSummary,
      scrollToTop,
      scrollToElement,
      downloadPdf
    }
  }
}
</script>

<style scoped>
/* Fade animation for title and description */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-in {
  opacity: 0;
  animation: fadeIn 0.8s ease-out forwards;
}

/* Slide fade transition for the form */
.slide-fade-enter-active {
  transition: all 0.5s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(20px);
  opacity: 0;
}
</style>