<template>
  <div class="receipt-page">
    <div v-if="loading" class="loading">
      <p>Loading your receipt...</p>
    </div>
    
    <div v-else-if="error" class="error-message">
      <p>{{ error }}</p>
      <p>Please try again or contact support if the issue persists.</p>
    </div>
    
    <div v-else class="receipt">
      <div class="header">
        <h1>Carolina's Finest Referee Camp</h1>
        <p>Registration Receipt</p>
      </div>
      
      <div class="confirmation-number">
        Confirmation Number: {{ receipt.confirmation_number }}
      </div>
      
      <div class="receipt-details">
        <h2>Registration Information</h2>
        <div class="details-row">
          <div class="details-label">Name:</div>
          <div class="details-value">{{ receipt.name }}</div>
        </div>
        <div class="details-row">
          <div class="details-label">Email:</div>
          <div class="details-value">{{ receipt.email }}</div>
        </div>
        <div class="details-row">
          <div class="details-label">Session:</div>
          <div class="details-value">{{ receipt.session }}</div>
        </div>
        <div class="details-row">
          <div class="details-label">Camp Date:</div>
          <div class="details-value">{{ receipt.camp_date }}</div>
        </div>
      </div>
      
      <div class="receipt-details">
        <h2>Payment Information</h2>
        <div class="details-row">
          <div class="details-label">Registration Date:</div>
          <div class="details-value">{{ formatDate(receipt.registration_date) }}</div>
        </div>
        <div class="details-row">
          <div class="details-label">Payment Date:</div>
          <div class="details-value">{{ formatDate(receipt.payment_date) }}</div>
        </div>
        <div class="details-row">
          <div class="details-label">Amount Paid:</div>
          <div class="details-value">{{ receipt.amount_paid }}</div>
        </div>
        <div class="details-row">
          <div class="details-label">Payment Status:</div>
          <div class="details-value">{{ receipt.payment_status }}</div>
        </div>
      </div>
      
      <div class="actions">
        <button class="btn" @click="printReceipt">Print Receipt</button>
        <router-link :to="{ name: 'status', params: { confirmation: receipt.confirmation_number }}" class="btn">View Status</router-link>
        <router-link to="/" class="btn secondary">Return Home</router-link>
      </div>
    </div>
    
    <div class="footer">
      <p v-if="receipt && receipt.camp_details">
        If you have any questions, please contact <a :href="'mailto:' + receipt.camp_details.contact_email">{{ receipt.camp_details.contact_email }}</a>
      </p>
      <p>&copy; {{ currentYear }} Carolina's Finest Referee Camp. All rights reserved.</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

// Helper to get the API URL based on current hostname
function getApiUrl() {
  const hostname = window.location.hostname;
  return `http://${hostname}:5000`;
}

export default {
  name: 'ReceiptView',
  data() {
    return {
      receipt: {},
      loading: true,
      error: null,
      currentYear: new Date().getFullYear()
    };
  },
  created() {
    const confirmationNumber = this.$route.query.confirmation;
    if (confirmationNumber) {
      this.fetchReceiptDetails(confirmationNumber);
    } else {
      this.error = 'No confirmation number provided';
      this.loading = false;
    }
  },
  methods: {
    async fetchReceiptDetails(confirmationNumber) {
      const apiUrl = getApiUrl();
      try {
        const response = await axios.get(`${apiUrl}/api/receipt/${confirmationNumber}`);
        this.receipt = response.data;
        this.loading = false;
      } catch (error) {
        console.error('Error fetching receipt details:', error);
        this.error = 'Unable to retrieve your receipt. Please try again later.';
        this.loading = false;
      }
    },
    formatDate(dateString) {
      if (!dateString) return 'N/A';
      
      const date = new Date(dateString);
      if (isNaN(date.getTime())) return dateString; // Return as is if not a valid date
      
      return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZoneName: 'short'
      }).format(date);
    },
    printReceipt() {
      window.print();
    }
  }
};
</script>

<style scoped>
.receipt-page {
  font-family: 'Arial', sans-serif;
  line-height: 1.6;
  color: #333;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.receipt {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  padding: 30px;
  margin-bottom: 30px;
}

.header {
  text-align: center;
  margin-bottom: 30px;
  border-bottom: 2px solid #eaeaea;
  padding-bottom: 20px;
}

.header h1 {
  color: #2c3e50;
  font-size: 28px;
  margin-bottom: 10px;
}

.confirmation-number {
  font-size: 22px;
  font-weight: bold;
  background-color: #f1f8ff;
  border: 1px dashed #a3cfff;
  padding: 10px;
  text-align: center;
  margin: 20px 0;
  border-radius: 5px;
}

.receipt-details {
  margin-bottom: 30px;
}

.receipt-details h2 {
  color: #2c3e50;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 10px;
  font-size: 20px;
}

.details-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 5px 0;
}

.details-label {
  font-weight: bold;
  flex: 1;
}

.details-value {
  flex: 2;
}

.actions {
  margin-top: 30px;
  text-align: center;
}

.btn {
  display: inline-block;
  background-color: #3498db;
  color: white;
  padding: 10px 15px;
  text-decoration: none;
  border-radius: 4px;
  font-weight: bold;
  margin: 10px 5px;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.btn:hover {
  background-color: #2980b9;
}

.btn.secondary {
  background-color: #95a5a6;
}

.btn.secondary:hover {
  background-color: #7f8c8d;
}

.footer {
  text-align: center;
  margin-top: 30px;
  font-size: 14px;
  color: #7f8c8d;
}

.loading, .error-message {
  margin: 20px 0;
  padding: 15px;
  border-radius: 5px;
  text-align: center;
}

.loading {
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
}

.error-message {
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  color: #721c24;
}

@media print {
  .actions, .footer {
    display: none;
  }
  
  .receipt {
    box-shadow: none;
    padding: 0;
  }
  
  .receipt-page {
    padding: 0;
  }
}
</style> 