import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/styles.css'
// import './registerServiceWorker' // Disabled to fix SecurityError during registration

// Clear any existing caches when the app starts
if ('caches' in window) {
  caches.keys().then(cacheNames => {
    cacheNames.forEach(cacheName => {
      caches.delete(cacheName)
    })
  })
}

createApp(App).use(router).mount('#app') 