<template>
  <div class="login-container">
    <div class="header">
      <h1>Carolina's Finest Referee Camp</h1>
      <p>Registration Login</p>
    </div>
    
    <div class="login-form">
      <div class="error-message" v-if="error">
        {{ error }}
      </div>
      
      <div class="success-message" v-if="showSuccessMessage">
        Login successful! Redirecting to your registration details...
      </div>
      
      <div class="login-tabs">
        <button 
          :class="['tab-btn', { active: loginMethod === 'confirmation' }]" 
          @click="loginMethod = 'confirmation'"
        >
          Confirmation Number
        </button>
        <button 
          :class="['tab-btn', { active: loginMethod === 'password' }]" 
          @click="loginMethod = 'password'"
        >
          Password
        </button>
      </div>
      
      <form @submit.prevent="handleLogin">
        <div class="form-group">
          <label for="email">Email Address</label>
          <input type="email" id="email" v-model="email" required>
        </div>
        
        <div class="form-group" v-if="loginMethod === 'confirmation'">
          <label for="confirmation_number">Confirmation Number</label>
          <input type="text" id="confirmation_number" v-model="confirmationNumber" required>
        </div>
        
        <div class="form-group" v-else>
          <label for="password">Password</label>
          <input type="password" id="password" v-model="password" required>
        </div>
        
        <button type="submit" class="btn" :disabled="loading || showSuccessMessage">
          {{ loading ? 'Logging in...' : 'Login' }}
        </button>
      </form>
      
      <div class="help-text">
        <p v-if="loginMethod === 'confirmation'">Your confirmation number was sent to your email after registration.</p>
        <p v-else>Your temporary password was provided on the registration confirmation page.</p>
        <p>If you need assistance, please contact <a href="mailto:camp@carolinasfinestrefereecamp.com">camp@carolinasfinestrefereecamp.com</a></p>
      </div>
    </div>
  </div>
</template>

<script>
import { login } from '../services/auth';

// Helper to get the API URL based on current hostname
function getApiUrl() {
  const hostname = window.location.hostname;
  return `http://${hostname}:5000`;
}

export default {
  name: 'LoginView',
  data() {
    return {
      email: '',
      confirmationNumber: '',
      password: '',
      loginMethod: 'confirmation',
      loading: false,
      error: null,
      showSuccessMessage: false
    };
  },
  methods: {
    async handleLogin() {
      this.loading = true;
      this.error = null;
      
      try {
        console.log('Starting login process...');
        
        // Add either confirmation number or password based on login method
        const credentials = {
          email: this.email,
          confirmationNumber: this.loginMethod === 'confirmation' ? this.confirmationNumber : null,
          password: this.loginMethod === 'password' ? this.password : null
        };
        
        // Validate inputs
        if (!credentials.email) {
          throw new Error('Email address is required');
        }
        
        if (this.loginMethod === 'confirmation' && !credentials.confirmationNumber) {
          throw new Error('Confirmation number is required');
        }
        
        if (this.loginMethod === 'password' && !credentials.password) {
          throw new Error('Password is required');
        }
        
        console.log(`Attempting login with ${this.loginMethod} method`);
        
        // Use the auth service to handle login
        const userData = await login(
          credentials.email, 
          credentials.confirmationNumber, 
          credentials.password
        );
        
        console.log('Login successful, redirecting to status page');
        
        // Show success message before redirecting
        this.showSuccessMessage = true;
        
        // Redirect to registration status page after a brief delay
        setTimeout(() => {
          this.$router.push({ 
            name: 'status', 
            params: { confirmation: userData.confirmation_number }
          });
        }, 1000);
      } catch (error) {
        console.error('Login error:', error);
        this.error = error.message || 'Login failed. Please check your credentials.';
        
        // Analytics or error tracking could be added here
        console.error('Login failed:', {
          method: this.loginMethod,
          error: this.error,
          timestamp: new Date().toISOString()
        });
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped>
.login-container {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  padding: 30px;
  margin: 20px auto;
  max-width: 800px;
}

.header {
  text-align: center;
  margin-bottom: 30px;
  border-bottom: 2px solid #eaeaea;
  padding-bottom: 20px;
}

.header h1 {
  color: #2c3e50;
  font-size: 28px;
  margin-bottom: 10px;
}

.login-form {
  max-width: 500px;
  margin: 0 auto;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

input[type="email"],
input[type="text"],
input[type="password"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.btn {
  display: inline-block;
  background-color: #3498db;
  color: white;
  padding: 12px 20px;
  text-decoration: none;
  border-radius: 4px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
}

.btn:hover {
  background-color: #2980b9;
}

.btn:disabled {
  background-color: #95a5a6;
  cursor: not-allowed;
}

.error-message {
  color: #e74c3c;
  background-color: #fadbd8;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
}

.success-message {
  color: #27ae60;
  background-color: #d4edda;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
  text-align: center;
  font-weight: bold;
}

.help-text {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
}

.login-tabs {
  display: flex;
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
}

.tab-btn {
  flex: 1;
  padding: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #7f8c8d;
  transition: all 0.3s ease;
}

.tab-btn.active {
  color: #3498db;
  border-bottom: 2px solid #3498db;
  font-weight: bold;
}

.tab-btn:hover:not(.active) {
  color: #2980b9;
  background-color: #f9f9f9;
}
</style> 